import React from 'react';

import { Conversation } from '@open-web/react-sdk';

import { environment } from '../../config/environment';
import { IGame } from '../../models/Game/Game';
import { UrlService } from '../../services/UrlService';

type OpenWebConversationProps = {
    topicId: string;
    game?: IGame;
    linkPath: string;
};

export const OpenWebConversation = React.memo((props: OpenWebConversationProps) => {
    const { topicId, linkPath } = props;
    const spotId = environment.OPEN_WEB_KEY;

    return (
        <Conversation
            spotId={spotId}
            postId={topicId}
            // articleTags={['tag1', 'tag2', 'tag3']}
            postUrl={UrlService.createURL(linkPath, false, false)}
        />
    );
});
