import React from 'react';

import classNames from 'classnames';

import { Button } from '../../atoms/Buttons/Base/Base';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { AndroidAppIcon, AppleAppIcon } from '../../atoms/Icons/Styleguide/AppIcon';
import { CloseIconSmall } from '../../atoms/Icons/Styleguide/CloseIconSmall';
import { LS_COOKIE_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { DeviceDetector } from '../../services/DeviceDetector';
import { LocalStorageService } from '../../services/LocalStorage';
import { TranslationService } from '../../services/TranslationService';
import styles from './GameAppBanner.css';

type GameAppBannerProps = {
    game: IGame;
};

type GameAppBannerState = {
    hidden: boolean;
};

const appIcon = {
    iOS: AppleAppIcon,
    Android: AndroidAppIcon,
    'Mac OS': AndroidAppIcon,
};

export class GameAppBanner extends React.PureComponent<GameAppBannerProps, GameAppBannerState> {
    readonly state = {
        hidden: Boolean(LocalStorageService.getItem(LS_COOKIE_CONSTS.GAME_APP_BANNER)),
    };

    private OS;

    private appComponent;

    componentDidMount() {
        const deviceDetector = new DeviceDetector();
        this.OS = deviceDetector.DetectOS()?.name;
        if (this.isOSSupported(this.OS)) {
            this.appComponent = appIcon[this.OS];
        } else {
            this.setState({ hidden: true });
        }
    }

    isOSSupported(OS) {
        let res = false;
        (this.props.game.appStore || []).forEach((item) => {
            if (item.OS === OS) {
                res = true;
            }
        });
        return res;
    }

    getLink() {
        let res = '';
        (this.props.game.appStore || []).forEach((item) => {
            if (item.OS === this.OS) {
                res = item.link;
            }
        });
        return res;
    }

    onClick() {
        window.location.href = this.getLink();
    }

    closeBanner() {
        this.setState({ hidden: true });
        LocalStorageService.setItem(LS_COOKIE_CONSTS.GAME_APP_BANNER, 1);
    }

    render() {
        const AppIcon = this.appComponent;
        return (
            <div
                className={classNames(styles.bannerContainerWrapper, {
                    [styles.hidden]: this.state.hidden,
                })}
            >
                <div className={styles.bannerContainer}>
                    <Button className={styles.closeIcon} onClick={() => this.closeBanner()} noPadding>
                        <CloseIconSmall />
                    </Button>
                    <Button className={styles.gameIcon} onClick={() => this.onClick()} noPadding>
                        <img alt="promo" data-testid="game-app-banner" src={this.props.game.promoTile280x280} />
                    </Button>
                    <div className={styles.textBlock}>
                        <div className={styles.topText}>
                            {TranslationService.translateIt('LOVE')} {this.props.game.name}?
                        </div>
                        <Button className={styles.bottomText} onClick={() => this.onClick()} noPadding>
                            <I18nText keyName="FREE_DOWNLOAD" />
                        </Button>
                    </div>
                    <Button className={styles.appIcon} onClick={() => this.onClick()} noPadding>
                        {this.appComponent && <AppIcon />}
                    </Button>
                </div>
            </div>
        );
    }
}
