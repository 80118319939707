import React from 'react';

import styles from './GamePageAdNewLayoutTest.css';
import { DesktopQuery, Responsive } from '../../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../../molecules/Ad/Ad';
import { GamePageDisplayAdProps } from '../GamePageDisplayAdProps';

export const GameAreaTopAdNewLayoutTest = React.memo((props: GamePageDisplayAdProps) => {
    return (
        <DesktopQuery>
            <div className={props.className}>
                <Responsive maxWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP}>
                    <Ad
                        adType={[AdTypes.AD_728x90]}
                        backgroundTone={props.adBackgroundTone}
                        keepSize
                        captionPosition={CaptionPositionTypes.CENTER}
                        id="ark_display_t1"
                        useDefaultBidTimeout
                        keyValues={props.keyValues}
                        className={styles.topAd720}
                    />
                </Responsive>
                <Responsive minWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP_ABOVE}>
                    <Ad
                        adType={[AdTypes.AD_970x90, AdTypes.AD_728x90]}
                        backgroundTone={props.adBackgroundTone}
                        keepSize
                        captionPosition={CaptionPositionTypes.CENTER}
                        id="ark_display_t1"
                        useDefaultBidTimeout
                        keyValues={props.keyValues}
                        className={styles.topAd720}
                    />
                </Responsive>
            </div>
        </DesktopQuery>
    );
});
