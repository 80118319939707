export function getLogo() {
    return `
    <svg
            width="140"
            height="42"
            class = "ctaArkLogo"
            viewBox="0 0 140 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M135.766 1.54696C136.125 1.55395 136.478 1.6421 136.798 1.80479C138.293 2.52413 138.345 3.68435 138.322 5.70313V21.4306C138.322 22.3897 137.878 23.9521 136.465 23.9521C136.114 23.9438 135.769 23.8676 135.447 23.7278C134.415 23.3101 134.005 21.7477 133.567 20.9098C132.995 19.814 131.979 17.7978 129.651 16.6814C128.641 16.2001 127.534 15.955 126.415 15.9646C125.569 15.9803 124.727 16.0814 123.901 16.2663C123.228 16.3978 122.285 16.7381 121.436 16.7381C121.124 16.7461 120.813 16.6854 120.526 16.5602C118.443 15.5882 119.603 13.6958 120.477 12.9945L132.667 3.25635C133.846 2.29466 134.743 1.55212 135.761 1.55212M135.761 0.00515705C134.214 0.00515705 133.062 0.943647 131.734 2.02652L131.701 2.0523L119.523 11.7827C118.562 12.5562 117.672 13.9665 117.842 15.3923C117.92 16.0317 118.286 17.2177 119.884 17.9705C120.373 18.1861 120.903 18.2925 121.436 18.2825C122.172 18.2551 122.902 18.1346 123.607 17.9241C123.829 17.8674 124.038 17.8133 124.198 17.7823L124.273 17.7669C124.975 17.6096 125.692 17.5232 126.412 17.509C127.304 17.4955 128.186 17.6879 128.991 18.0711C130.796 18.9451 131.628 20.5153 132.17 21.5543L132.206 21.6214C132.281 21.7657 132.371 21.9746 132.464 22.1963C132.899 23.2096 133.495 24.5993 134.872 25.1562C135.379 25.3711 135.922 25.4858 136.473 25.4939C137.312 25.5078 138.121 25.1781 138.711 24.5812C139.809 23.4803 139.889 21.8869 139.889 21.4228V5.6954C139.907 3.75912 139.928 1.57017 137.486 0.394476C136.95 0.134576 136.362 -0.000302472 135.766 5.09316e-07L135.761 0.00515705Z"
                fill="#D50032"
            />
            <path
                d="M22.431 41.0923H21.0078V26.3291H22.431V29.0905C23.4623 27.2057 25.182 26.1435 27.2704 26.1435C28.0844 26.1251 28.8925 26.2862 29.6372 26.6153L29.7584 26.672V28.219L29.4567 28.0772C28.7469 27.7116 27.9579 27.5265 27.1595 27.5383C24.4523 27.5461 22.431 30.0883 22.431 33.4581V41.0923Z"
                fill="#F9F9F9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.5116 33.6697C45.5116 38.2925 48.6236 41.2781 53.4372 41.2781C55.6442 41.2781 57.5727 40.6954 59.0424 39.5919V41.0925H60.432V26.3293H59.0475V27.8273C57.5908 26.7367 55.639 26.1411 53.4656 26.1411C48.6339 26.1411 45.5116 29.0958 45.5116 33.6697ZM46.9503 33.68C46.9503 29.859 49.4667 27.4921 53.5197 27.4921C55.7241 27.4921 57.7326 28.214 59.0475 29.4774V37.9857C57.7635 39.2619 55.8453 39.9374 53.4939 39.9374C49.4563 39.9374 46.9503 37.5474 46.9503 33.68Z"
                fill="#F9F9F9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.575 33.6694C63.575 38.2923 66.6869 41.2779 71.5005 41.2779C73.7075 41.2779 75.6335 40.6952 77.1109 39.5917V41.0923H78.4954V20.6079H77.1109V27.8271C75.6722 26.7519 73.6663 26.1409 71.5289 26.1409C66.6972 26.1409 63.575 29.0956 63.575 33.6694ZM65.0136 33.6797C65.0136 29.8587 67.53 27.4919 71.5831 27.4919C73.7875 27.4919 75.8011 28.2138 77.1083 29.4746V37.9829C75.7676 39.2436 73.8004 39.9372 71.5547 39.9372C67.5197 39.9372 65.0136 37.5471 65.0136 33.6797Z"
                fill="#F9F9F9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.2468 22.7611C82.2468 23.4204 82.7812 23.9549 83.4405 23.9549C84.0998 23.9549 84.6342 23.4204 84.6342 22.7611C84.6342 22.1018 84.0998 21.5674 83.4405 21.5674C82.7812 21.5674 82.2468 22.1018 82.2468 22.7611ZM82.7083 41.0926H84.1186V26.3294H82.7083V41.0926Z"
                fill="#F9F9F9"
            />
            <path
                d="M94.3131 41.2779C89.9146 41.2779 87.9525 39.3004 87.9525 34.8683V26.3291H89.3345V34.8683C89.3345 38.416 90.8402 39.9295 94.3672 39.9295C96.5046 39.9295 98.7245 39.156 100.057 37.97V26.3291H101.468V41.0923H100.057V39.4654C98.7993 40.4064 96.5588 41.2779 94.3131 41.2779Z"
                fill="#F9F9F9"
            />
            <path
                d="M128.893 41.0923H127.511V32.4731C127.511 28.9822 126.186 27.4919 123.081 27.4919C121.12 27.5001 119.212 28.1252 117.626 29.2787C117.955 30.3106 118.11 31.3902 118.085 32.4731V41.0923H116.692V32.4731C116.692 28.9822 115.403 27.4919 112.345 27.4919C109.857 27.4919 108.091 28.4897 106.82 29.3869V41.0923H105.41V26.3291H106.82V27.9508C107.98 27.1774 110.015 26.1461 112.456 26.1461C114.705 26.1461 116.233 26.8164 117.123 28.1958C118.862 26.8594 120.996 26.1384 123.19 26.1461C127.132 26.1461 128.893 28.0978 128.893 32.4757V41.0923Z"
                fill="#F9F9F9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.86 41.0927H17.2976L14.8534 35.7557H3.99889L1.56501 41.0927H0L8.89761 21.9155H9.96243L10.0192 22.0341L18.86 41.0927ZM9.41584 23.8802L4.64088 34.3557L14.2114 34.3583L9.41584 23.8802Z"
                fill="#F9F9F9"
            />
            <path d="M44.604 41.0074V41.0873H44.6994L44.604 41.0074Z" fill="#F9F9F9" />
            <path d="M33.4968 23.9029V23.7869V20.6079H32.0994V41.0923H33.4968V23.9029Z" fill="#F9F9F9" />
            <path
                d="M36.5521 33.0328L44.1967 26.4995H41.9072L34.34 33.087L42.8663 40.8656H45.2203L36.5521 33.0328Z"
                fill="#F9F9F9"
            />
        </svg>
    `;
}
