import React from 'react';

import classNames from 'classnames';

import { UrlService } from '../../services/UrlService';
import styles from './Leaderboard.skeleton.css';

function generateRows(): JSX.Element[] {
    const rowCount = 100;
    const rows = [];
    for (let index = 0; index < rowCount; index++) {
        const striped = index % 2 === 0;
        rows.push(<LeaderboardRow striped={striped} key={index} />);
    }
    return rows;
}

export const LeaderboardSkeleton = React.memo(() => {
    const rows = generateRows();
    return (
        <div className={styles.leaderboardSkeletonContainer}>
            <div className={styles.leaderboardContent}>
                <div className={styles.leaderboardHeader} />
                <div className={styles.leaderboardBody}>
                    <div className={styles.shadow} />
                    {rows};
                </div>
            </div>
            <div className={styles.gamePromoContainer}>
                <img
                    alt="skeleton"
                    className={styles.gamePromo}
                    src={UrlService.toCDNUrl('/images/skeleton_rectangle.png')}
                />
            </div>
        </div>
    );
});

type leaderboardRowProps = {
    striped: boolean;
};

export const LeaderboardRow = React.memo((props: leaderboardRowProps) => {
    return (
        <div
            className={classNames(styles.leaderboardRow, {
                [styles.rowStriped]: props.striped,
            })}
        >
            <div
                className={classNames(styles.avatar, {
                    [styles.avatarStriped]: props.striped,
                })}
            />
        </div>
    );
});
