import React, { useState } from 'react';

import classNames from 'classnames';

import styles from './Tooltip.css';

type TooltipProps = {
    show: boolean;
    showDelay?: number;
    text: string;
    maxWidth?: number;
    topMargin?: number;
    targetBoundingClientRect?: any;
    className?: string;
};

export const Tooltip = React.memo((props: TooltipProps) => {
    let timeout;
    const { show, showDelay = 400, targetBoundingClientRect: rect, maxWidth = 300, topMargin = 5, className } = props;

    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, showDelay);
    };

    const hideTip = () => {
        clearInterval(timeout);
        if (active) {
            setActive(false);
        }
    };

    // tslint:disable-next-line: no-shadowed-variable
    const calculatePosition = (rectangle: any, maxWidth: number, topMargin: number) => {
        let style = {};
        if (rectangle) {
            const tooltipTop = rectangle.top + rectangle.height + topMargin;
            const tooltipRight = document.documentElement.clientWidth - rectangle.right;
            style = {
                top: `${tooltipTop}px`,
                right: `${tooltipRight}px`,
                maxWidth: `${maxWidth}px`,
            };
        }
        return style;
    };

    if (show) {
        showTip();
    } else {
        hideTip();
    }

    const tooltipStyle = calculatePosition(rect, maxWidth, topMargin);

    return (
        <div
            className={classNames(className, styles.tooltipTip, {
                [styles.screenReaderOnly]: !active,
            })}
            style={tooltipStyle}
            role="tooltip"
            aria-label="tooltip"
        >
            {props.text}
        </div>
    );
});
