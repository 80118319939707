import React from 'react';

import { TFunction } from 'react-i18next';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { MobileQueryOrientation, Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { HeaderSideMenuTabs } from '../../../constants/HeaderSideMenuTabs';
import { HelpFormStatus, HelpTabSupportFormTypes } from '../../../models/HelpFormStatus';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';
import { SupportCard, SupportCardMode } from '../../../molecules/Cards/SupportCard/SupportCard';
import { UrlService } from '../../../services/UrlService';
import { setHelpTabFormActive, setSideMenuActivePage, setSideMenuOpened } from '../../../store/ducks/layout';
import { GamePageDisplayAdProps } from './GamePageDisplayAdProps';

type GameAreaRightAdMobileProps = {
    supportCardClassName?: string;
    isFlexOnMobile?: boolean;
    dispatch?: Dispatch<Action>;
    t: TFunction;
} & GamePageDisplayAdProps;

const GameAreaRightAdMobileBase = React.memo((props: GameAreaRightAdMobileProps) => {
    return (
        <Responsive
            orientation={MobileQueryOrientation.LANDSCAPE}
            maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}
            minHeight={690}
            maxHeight={1024}
        >
            <div className={props.className}>
                {!props.isFlexOnMobile ? (
                    <Ad
                        adType={[AdTypes.AD_160x600]}
                        captionPosition={CaptionPositionTypes.CENTER}
                        backgroundTone={props.adBackgroundTone}
                        keepSize
                        id="ark_display_r1"
                        useDefaultBidTimeout
                    />
                ) : (
                    <>
                        <Ad
                            adType={[AdTypes.AD_300x250]}
                            captionPosition={CaptionPositionTypes.CENTER}
                            backgroundTone={props.adBackgroundTone}
                            keepSize
                            id="ark_display_r1"
                            useDefaultBidTimeout
                        />

                        <SupportCard
                            className={props.supportCardClassName}
                            image={UrlService.toCDNUrl('/illustrations/support/illustrations-support-support-1.png')}
                            onClick={() => {
                                props.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
                                props.dispatch(setSideMenuOpened(true));
                                props.dispatch(
                                    setHelpTabFormActive(new HelpFormStatus(true, HelpTabSupportFormTypes.SUPPORT_FORM))
                                );
                            }}
                            mode={SupportCardMode.ROW}
                            caption={props.t('HELP_TAB_FEEDBACK_HEADING')}
                            dataElementDescription="gamepage-provide-feedback"
                        />

                        <SupportCard
                            className={props.supportCardClassName}
                            image={UrlService.toCDNUrl('/illustrations/support/illustrations-support-support-1.png')}
                            onClick={() => {
                                props.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
                                props.dispatch(setSideMenuOpened(true));
                                props.dispatch(
                                    setHelpTabFormActive(new HelpFormStatus(true, HelpTabSupportFormTypes.SUPPORT_FORM))
                                );
                            }}
                            mode={SupportCardMode.ROW}
                            caption={props.t('HELP_TAB_FEEDBACK_HEADING')}
                            dataElementDescription="gamepage-provide-feedback"
                        />

                        <Ad
                            adType={[AdTypes.AD_300x250]}
                            captionPosition={CaptionPositionTypes.CENTER}
                            backgroundTone={props.adBackgroundTone}
                            keepSize
                            id="ark_display_r2"
                            useDefaultBidTimeout
                        />
                    </>
                )}
            </div>
        </Responsive>
    );
});

export const GameAreaRightAdMobile = connect()(GameAreaRightAdMobileBase);
