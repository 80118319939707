import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './BottomPromoBlock.css';
import { IGame } from '../../../../models/Game/Game';
import { AdDividerPromoCard } from '../../../../molecules/Cards/AdDividerPromoCard/AdDividerPromoCard';
import { GamePageBottomAd } from '../../../../organisms/DisplayAd/GamePageAd/GamePageBottomAd';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { Media } from '../../../../services/MediaService';
import { UrlService } from '../../../../services/UrlService';

interface IBottomPromoBlock {
    game: IGame;
}

const trackClick = (game: IGame, link: string, event) => {
    event.preventDefault();
    Analytics.trackEvent(Analytics.games.gamePageAdDividerClick(game));
    setTimeout(() => window.open(link, '_blank'), 300);
};
const BottomPromoBlock = React.forwardRef<any, any>((props: IBottomPromoBlock, ref: any) => {
    const { game } = props;
    const { t } = useTranslation();
    const getControls = (): ReactElement => (
        <>
            <div className={styles.gameLicensingContainer} ref={ref}>
                <AdDividerPromoCard
                    newWindow
                    url="https://corporate.arkadium.com/game-licensing/"
                    imagePath={UrlService.createURL(
                        '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-game-licening.png',
                        true
                    )}
                    caption={t('LICENSING_GAME.TEXT_2')}
                    captionTag="h3"
                    buttonDisabled
                    buttonCaption="Games Licensing"
                    adDividerClassName={styles.adDividerClassName}
                    renderDescription={() => {
                        return (
                            <>
                                {t('LICENSING_GAME.TEXT_1')}
                                <a
                                    rel="noopener noreferrer"
                                    onClick={(event) =>
                                        trackClick(game, 'https://corporate.arkadium.com/game-licensing/', event)
                                    }
                                    href="https://corporate.arkadium.com/game-licensing/"
                                    target="_blank"
                                    className={styles.tagSpacing}
                                >
                                    {t('LICENSING_GAME.TEXT_2')}
                                </a>
                                {t('LICENSING_GAME.TEXT_3')}
                                <a
                                    rel="noopener noreferrer"
                                    onClick={(event) =>
                                      trackClick(game, 'https://corporate.arkadium.com/arena/', event)
                                    }
                                    href="https://corporate.arkadium.com/arena/"
                                    target="_blank"
                                    className={styles.tagSpacing}
                                >
                                    {t('LICENSING_GAME.TEXT_4')}
                                </a>
                                {t('LICENSING_GAME.TEXT_5')}
                            </>
                        );
                    }}
                />
            </div>
            <GamePageBottomAd className={styles.gameAreaFooterBottomAdContainer} />
        </>
    );

    return (
        <div className={styles.gameAreaBottom}>
            <Media between={['ARK_SMALL_DESKTOP_BELOW', 'ARK_MEDIUM_DESKTOP_BELOW']}>
                <div className={styles.gameAreaBottomPromoContainer}>{getControls()}</div>
            </Media>
            <Media greaterThan="ARK_MEDIUM_DESKTOP_BELOW">{getControls()}</Media>
        </div>
    );
});

export default BottomPromoBlock;
