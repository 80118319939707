import { globalLogHandler } from '../../utils/LogUtils';
import { environment } from '../config/environment';

function logOpenWebAuthEvent(msg) {
  globalLogHandler({ msg, filename: 'OpenWebService.ts', value: '%c Log:OpenWebAuth' });
}

export function OpenWebService(userId, apiToken: string) {
  if ((window as any).SPOTIM && (window as any).SPOTIM.startSSO) {
    startSSO();
  } else {
    document.addEventListener('spot-im-api-ready', startSSO, false);
  }

  // In case of token expiration we need to renew sso
  document.addEventListener('spot-im-renew-sso', startSSO, false);

  // Prior to initiating this function, ensure that the user
  // is actively logged into your site
  function startSSO() {
    const callback = function (codeA, completeSSOCallback) {
      // call your backend to receive codeB and return it
      // to OpenWeb via completeSSOCallback function
      const url = `${environment.EAGLE_API_BASE_URL}auth/sso/openweb`;

      fetch(url, {
        method: 'POST',
        body: JSON.stringify({ codeA: codeA }),
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((data) => {
              throw new Error(JSON.stringify(data));
            });
          }
        })
        .then((data: { codeB: string }) => {
          if (data.codeB) {
            completeSSOCallback(data.codeB);
          }
        })
        .catch((err) => {
          completeSSOCallback(null, err);
        });
    };

    (window as any).SPOTIM.startSSO({ callback: callback, userId: userId }) // '<unique-user-id>'
      .then(function () {
        // logOpenWebAuthEvent(`OpenWeb Auth is successful. Data is:${JSON.stringify(userData)}`);
        // userData contains information about the logged-in user
      })
      .catch(function (reason) {
        logOpenWebAuthEvent(`OpenWeb Auth was failed. Reason is:${reason}`);
        // reason contains error details
      });
  }
}
