import { StringUtils } from '../../../../utils/StringUtils';
import { additionalNonEnSupportedLocales } from '../../../constants/Locales';
import { IGame } from '../../../models/Game/Game';
import { GameAreaBackgroundColorTone } from '../../../models/Game/GameModel';
import { BackgroundToneTypes } from '../../../molecules/Ad/Ad';
import { UrlService } from '../../../services/UrlService';

const COUNTRIES = {
    nz: 'New Zealand',
    cn: 'Canada',
    au: 'Australia',
    gb: 'the United Kingdom',
};

export const getTitleWithCountry = (title: string, currentLang: string): string => {
    if (!additionalNonEnSupportedLocales.find((l) => StringUtils.equalIgnoreCase(l, UrlService.currentLang))) {
        return title;
    }

    if (title.toLowerCase().endsWith('arkadium')) {
        return `${title} ${COUNTRIES[currentLang]}`;
    }

    return `${title} in ${COUNTRIES[currentLang]}`;
};

export const getAdBackgroundTone = (game: IGame): BackgroundToneTypes =>
    game.webGameAreaBackgroundColorTone === GameAreaBackgroundColorTone.DARK
        ? BackgroundToneTypes.DARK
        : BackgroundToneTypes.LIGHT;
