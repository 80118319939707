import React from 'react';

type iconProps = {
    className?: string;
    fullScreen: boolean;
};

export const FullScreen = React.memo((props: iconProps) => {
    const on = (
        <svg
            className={props.className}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.4586 10.0514L3.39684 1.98682L7.92226 1.98682C8.18557 1.98682 8.4381 1.88215 8.62429 1.69585C8.81048 1.50955 8.91508 1.25688 8.91508 0.993408C8.91508 0.72994 8.81048 0.477264 8.62429 0.290964C8.4381 0.104664 8.18557 -6.4373e-07 7.92226 -6.4373e-07L0.995594 -6.4373e-07C0.962576 -2.23491e-05 0.929583 0.00182991 0.896774 0.00554504C0.876455 0.00554504 0.857984 0.0110885 0.838589 0.0138608C0.819195 0.0166331 0.813653 0.013863 0.801646 0.0194076C0.78964 0.0249522 0.75547 0.0304956 0.732381 0.0360402L0.709292 0.0425103C0.684356 0.0499031 0.659419 0.0582187 0.635407 0.0674597L0.618783 0.0739288C0.592 0.085018 0.567065 0.0970311 0.542129 0.109968L0.531044 0.114589C0.371047 0.198931 0.237059 0.325323 0.14349 0.480169C0.049921 0.635016 0.000311424 0.812458 4.76837e-07 0.993408C4.76837e-07 1.00265 4.76837e-07 1.01097 4.76837e-07 1.01928L4.76837e-07 7.92416C4.76837e-07 8.18763 0.104601 8.44031 0.290791 8.62661C0.476981 8.81291 0.72951 8.91757 0.992823 8.91757C1.25614 8.91757 1.50866 8.81291 1.69485 8.62661C1.88104 8.44031 1.98565 8.18763 1.98565 7.92416L1.98565 3.39607L10.0529 11.4588C10.2394 11.6454 10.4924 11.7501 10.7561 11.75C11.0198 11.7499 11.2726 11.645 11.459 11.4584C11.6454 11.2717 11.7501 11.0187 11.75 10.7548C11.7499 10.491 11.6451 10.2379 11.4586 10.0514Z"
                fill="currentColor"
            />
            <path
                d="M10.0514 18.5414L1.98682 26.6032V22.0777C1.98682 21.8144 1.88215 21.5619 1.69585 21.3757C1.50955 21.1895 1.25688 21.0849 0.993408 21.0849C0.72994 21.0849 0.477264 21.1895 0.290964 21.3757C0.104664 21.5619 -6.4373e-07 21.8144 -6.4373e-07 22.0777V29.0044C-2.23491e-05 29.0374 0.00182991 29.0704 0.00554504 29.1032C0.00554504 29.1235 0.0110885 29.142 0.0138608 29.1614C0.0166331 29.1808 0.013863 29.1863 0.0194076 29.1984C0.0249522 29.2104 0.0304956 29.2445 0.0360402 29.2676L0.0425103 29.2907C0.0499031 29.3156 0.0582187 29.3406 0.0674597 29.3646L0.0739288 29.3812C0.085018 29.408 0.0970311 29.4329 0.109968 29.4579L0.114589 29.469C0.198931 29.629 0.325323 29.7629 0.480169 29.8565C0.635016 29.9501 0.812458 29.9997 0.993408 30C1.00265 30 1.01097 30 1.01928 30H7.92416C8.18763 30 8.44031 29.8954 8.62661 29.7092C8.81291 29.523 8.91757 29.2705 8.91757 29.0072C8.91757 28.7439 8.81291 28.4913 8.62661 28.3051C8.44031 28.119 8.18763 28.0144 7.92416 28.0144H3.39607L11.4588 19.9471C11.6454 19.7606 11.7501 19.5076 11.75 19.2439C11.7499 18.9802 11.645 18.7274 11.4584 18.541C11.2717 18.3546 11.0187 18.2499 10.7548 18.25C10.491 18.2501 10.2379 18.3549 10.0514 18.5414Z"
                fill="currentColor"
            />
            <path
                d="M19.9486 11.4586L28.0132 3.39684V7.92226C28.0132 8.18557 28.1178 8.4381 28.3041 8.62429C28.4904 8.81048 28.7431 8.91508 29.0066 8.91508C29.2701 8.91508 29.5227 8.81048 29.709 8.62429C29.8953 8.4381 30 8.18557 30 7.92226V0.995594C30 0.962576 29.9982 0.929583 29.9945 0.896774C29.9945 0.876455 29.9889 0.857984 29.9861 0.838589C29.9834 0.819195 29.9861 0.813653 29.9806 0.801646C29.975 0.78964 29.9695 0.75547 29.964 0.732381L29.9575 0.709292C29.9501 0.684356 29.9418 0.659419 29.9325 0.635407L29.9261 0.618783C29.915 0.592 29.903 0.567065 29.89 0.542129L29.8854 0.531044C29.8011 0.371047 29.6747 0.237059 29.5198 0.14349C29.365 0.049921 29.1875 0.000311424 29.0066 4.76837e-07C28.9974 4.76837e-07 28.989 4.76837e-07 28.9807 4.76837e-07L22.0758 4.76837e-07C21.8124 4.76837e-07 21.5597 0.104601 21.3734 0.290791C21.1871 0.476981 21.0824 0.72951 21.0824 0.992823C21.0824 1.25614 21.1871 1.50866 21.3734 1.69485C21.5597 1.88104 21.8124 1.98565 22.0758 1.98565H26.6039L18.5412 10.0529C18.3546 10.2394 18.2499 10.4924 18.25 10.7561C18.2501 11.0198 18.355 11.2726 18.5416 11.459C18.7283 11.6454 18.9813 11.7501 19.2452 11.75C19.509 11.7499 19.7621 11.6451 19.9486 11.4586Z"
                fill="currentColor"
            />
            <path
                d="M18.5414 19.9486L26.6032 28.0132H22.0777C21.8144 28.0132 21.5619 28.1178 21.3757 28.3041C21.1895 28.4904 21.0849 28.7431 21.0849 29.0066C21.0849 29.2701 21.1895 29.5227 21.3757 29.709C21.5619 29.8953 21.8144 30 22.0777 30H29.0044C29.0374 30 29.0704 29.9982 29.1032 29.9945C29.1235 29.9945 29.142 29.9889 29.1614 29.9861C29.1808 29.9834 29.1863 29.9861 29.1984 29.9806C29.2104 29.975 29.2445 29.9695 29.2676 29.964L29.2907 29.9575C29.3156 29.9501 29.3406 29.9418 29.3646 29.9325L29.3812 29.9261C29.408 29.915 29.4329 29.903 29.4579 29.89L29.469 29.8854C29.629 29.8011 29.7629 29.6747 29.8565 29.5198C29.9501 29.365 29.9997 29.1875 30 29.0066C30 28.9974 30 28.989 30 28.9807V22.0758C30 21.8124 29.8954 21.5597 29.7092 21.3734C29.523 21.1871 29.2705 21.0824 29.0072 21.0824C28.7439 21.0824 28.4913 21.1871 28.3051 21.3734C28.119 21.5597 28.0144 21.8124 28.0144 22.0758V26.6039L19.9471 18.5412C19.7606 18.3546 19.5076 18.2499 19.2439 18.25C18.9802 18.2501 18.7274 18.355 18.541 18.5416C18.3546 18.7283 18.2499 18.9813 18.25 19.2452C18.2501 19.509 18.3549 19.7621 18.5414 19.9486Z"
                fill="currentColor"
            />
        </svg>
    );

    const off = (
        <svg
            className={props.className}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.291447 1.69856L8.35316 9.76318H3.82774C3.56443 9.76318 3.3119 9.86785 3.12571 10.0541C2.93952 10.2404 2.83492 10.4931 2.83492 10.7566C2.83492 11.0201 2.93952 11.2727 3.12571 11.459C3.3119 11.6453 3.56443 11.75 3.82774 11.75H10.7544C10.7874 11.75 10.8204 11.7482 10.8532 11.7445C10.8735 11.7445 10.892 11.7389 10.9114 11.7361C10.9308 11.7334 10.9363 11.7361 10.9484 11.7306C10.9604 11.725 10.9945 11.7195 11.0176 11.714L11.0407 11.7075C11.0656 11.7001 11.0906 11.6918 11.1146 11.6825L11.1312 11.6761C11.158 11.665 11.1829 11.653 11.2079 11.64L11.219 11.6354C11.379 11.5511 11.5129 11.4247 11.6065 11.2698C11.7001 11.115 11.7497 10.9375 11.75 10.7566C11.75 10.7474 11.75 10.739 11.75 10.7307V3.82584C11.75 3.56237 11.6454 3.30969 11.4592 3.12339C11.273 2.93709 11.0205 2.83243 10.7572 2.83243C10.4939 2.83243 10.2413 2.93709 10.0551 3.12339C9.86896 3.30969 9.76435 3.56237 9.76435 3.82584V8.35393L1.6971 0.291156C1.51057 0.104645 1.25764 -8.65994e-05 0.993946 5.37285e-08C0.730248 8.67069e-05 0.477386 0.104985 0.290985 0.291618C0.104584 0.478252 -8.65445e-05 0.731333 5.36919e-08 0.995185C8.66519e-05 1.25904 0.104923 1.51205 0.291447 1.69856Z"
                fill="currentColor"
            />
            <path
                d="M1.69856 29.7086L9.76318 21.6468V26.1723C9.76318 26.4356 9.86785 26.6881 10.0541 26.8743C10.2404 27.0605 10.4931 27.1651 10.7566 27.1651C11.0201 27.1651 11.2727 27.0605 11.459 26.8743C11.6453 26.6881 11.75 26.4356 11.75 26.1723V19.2456C11.75 19.2126 11.7482 19.1796 11.7445 19.1468C11.7445 19.1265 11.7389 19.108 11.7361 19.0886C11.7334 19.0692 11.7361 19.0637 11.7306 19.0516C11.725 19.0396 11.7195 19.0055 11.714 18.9824L11.7075 18.9593C11.7001 18.9344 11.6918 18.9094 11.6825 18.8854L11.6761 18.8688C11.665 18.842 11.653 18.8171 11.64 18.7921L11.6354 18.781C11.5511 18.621 11.4247 18.4871 11.2698 18.3935C11.115 18.2999 10.9375 18.2503 10.7566 18.25C10.7474 18.25 10.739 18.25 10.7307 18.25H3.82584C3.56237 18.25 3.30969 18.3546 3.12339 18.5408C2.93709 18.727 2.83243 18.9795 2.83243 19.2428C2.83243 19.5061 2.93709 19.7587 3.12339 19.9449C3.30969 20.131 3.56237 20.2356 3.82584 20.2356H8.35393L0.291156 28.3029C0.104645 28.4894 -8.65994e-05 28.7424 5.37285e-08 29.0061C8.67069e-05 29.2698 0.104985 29.5226 0.291618 29.709C0.478252 29.8954 0.731333 30.0001 0.995185 30C1.25904 29.9999 1.51205 29.8951 1.69856 29.7086Z"
                fill="currentColor"
            />
            <path
                d="M28.3014 0.291447L20.2368 8.35316V3.82774C20.2368 3.56443 20.1322 3.3119 19.9459 3.12571C19.7596 2.93952 19.5069 2.83492 19.2434 2.83492C18.9799 2.83492 18.7273 2.93952 18.541 3.12571C18.3547 3.3119 18.25 3.56443 18.25 3.82774V10.7544C18.25 10.7874 18.2518 10.8204 18.2555 10.8532C18.2555 10.8735 18.2611 10.892 18.2639 10.9114C18.2666 10.9308 18.2639 10.9363 18.2694 10.9484C18.275 10.9604 18.2805 10.9945 18.286 11.0176L18.2925 11.0407C18.2999 11.0656 18.3082 11.0906 18.3175 11.1146L18.3239 11.1312C18.335 11.158 18.347 11.1829 18.36 11.2079L18.3646 11.219C18.4489 11.379 18.5753 11.5129 18.7302 11.6065C18.885 11.7001 19.0625 11.7497 19.2434 11.75C19.2526 11.75 19.261 11.75 19.2693 11.75H26.1742C26.4376 11.75 26.6903 11.6454 26.8766 11.4592C27.0629 11.273 27.1676 11.0205 27.1676 10.7572C27.1676 10.4939 27.0629 10.2413 26.8766 10.0551C26.6903 9.86896 26.4376 9.76435 26.1742 9.76435H21.6461L29.7088 1.6971C29.8954 1.51057 30.0001 1.25764 30 0.993946C29.9999 0.730248 29.895 0.477386 29.7084 0.290985C29.5217 0.104584 29.2687 -8.65445e-05 29.0048 5.36919e-08C28.741 8.66519e-05 28.4879 0.104923 28.3014 0.291447Z"
                fill="currentColor"
            />
            <path
                d="M29.7086 28.3014L21.6468 20.2368H26.1723C26.4356 20.2368 26.6881 20.1322 26.8743 19.9459C27.0605 19.7596 27.1651 19.5069 27.1651 19.2434C27.1651 18.9799 27.0605 18.7273 26.8743 18.541C26.6881 18.3547 26.4356 18.25 26.1723 18.25H19.2456C19.2126 18.25 19.1796 18.2518 19.1468 18.2555C19.1265 18.2555 19.108 18.2611 19.0886 18.2639C19.0692 18.2666 19.0637 18.2639 19.0516 18.2694C19.0396 18.275 19.0055 18.2805 18.9824 18.286L18.9593 18.2925C18.9344 18.2999 18.9094 18.3082 18.8854 18.3175L18.8688 18.3239C18.842 18.335 18.8171 18.347 18.7921 18.36L18.781 18.3646C18.621 18.4489 18.4871 18.5753 18.3935 18.7302C18.2999 18.885 18.2503 19.0625 18.25 19.2434C18.25 19.2526 18.25 19.261 18.25 19.2693V26.1742C18.25 26.4376 18.3546 26.6903 18.5408 26.8766C18.727 27.0629 18.9795 27.1676 19.2428 27.1676C19.5061 27.1676 19.7587 27.0629 19.9449 26.8766C20.131 26.6903 20.2356 26.4376 20.2356 26.1742V21.6461L28.3029 29.7088C28.4894 29.8954 28.7424 30.0001 29.0061 30C29.2698 29.9999 29.5226 29.895 29.709 29.7084C29.8954 29.5217 30.0001 29.2687 30 29.0048C29.9999 28.741 29.8951 28.4879 29.7086 28.3014Z"
                fill="currentColor"
            />
        </svg>
    );
    return <>{props.fullScreen ? off : on}</>;
});
