import React from 'react';

import { MobileQueryOrientation, Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';
import styles from './GamePageAd.css';
import { GamePageDisplayAdProps } from './GamePageDisplayAdProps';

export const GameAreaTopAdMobileLandscape = React.memo((props: GamePageDisplayAdProps) => {
    return (
        <Responsive
            orientation={MobileQueryOrientation.LANDSCAPE}
            maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}
            minHeight={575}
            maxHeight={690}
        >
            <div className={props.className}>
                <Ad
                    className={styles.mobileTopAd}
                    adType={[AdTypes.AD_320x100, AdTypes.AD_320x50]}
                    backgroundTone={props.adBackgroundTone}
                    keepSize
                    captionPosition={CaptionPositionTypes.CENTER}
                    id="ark_display_m1"
                    useDefaultBidTimeout
                    hideCaption
                />
            </div>
        </Responsive>
    );
});
