import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const FullScreenArrow = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="arrow-full-screen-icon"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="0.999985" width="28" height="28" rx="14" stroke="currentColor" strokeWidth="2" />
            <mask id="path-2-inside-1_331_1842" fill="white">
                <path d="M12.6923 21.9231L19.6244 14.9281L12.6923 8.0769" />
            </mask>
            <path
                d="M11.2717 20.5152C10.4942 21.2998 10.4999 22.5661 11.2845 23.3436C12.0691 24.1212 13.3354 24.1154 14.1129 23.3309L11.2717 20.5152ZM19.6244 14.9281L21.045 16.3359C21.4187 15.9588 21.6272 15.4486 21.6244 14.9177C21.6216 14.3868 21.4079 13.8788 21.0303 13.5056L19.6244 14.9281ZM14.0982 6.65441C13.3126 5.87796 12.0463 5.8854 11.2698 6.67102C10.4934 7.45664 10.5008 8.72295 11.2864 9.4994L14.0982 6.65441ZM14.1129 23.3309L21.045 16.3359L18.2038 13.5203L11.2717 20.5152L14.1129 23.3309ZM21.0303 13.5056L14.0982 6.65441L11.2864 9.4994L18.2185 16.3506L21.0303 13.5056Z"
                fill="currentColor"
                mask="url(#path-2-inside-1_331_1842)"
            />
        </svg>
    );
});
