import React from 'react';

import classNames from 'classnames';

import { LazyImage } from '../../atoms/ProgressiveImage/LazyImage';
import { I18nText } from '../../atoms/i18nText/i18nText';
import styles from './CollectionItemCard.css';

export const enum BackgroundTypes {
    DARK = 'dark',
    LIGHT = 'light',
}
const enum RarenessTypes {
    RARE = 'RARE',
    VERY_RARE = 'VERY RARE',
    ULTRA_RARE = 'ULTRA RARE',
}
const RarenessIndexTypesMap = {
    1: RarenessTypes.RARE,
    2: RarenessTypes.VERY_RARE,
    3: RarenessTypes.ULTRA_RARE,
};

const tagNewText = 'NEW';
const tagNewAria = 'NEW_COLLECTIBLE_IN_COLLECTION';
// ToDo add translation for the tag

type CollectionItemCardProps = {
    isDiscovered: boolean;
    cardName: string;
    imageSrc: string;
    rarenessIndex: 1 | 2 | 3;
    backgroundType: BackgroundTypes;
    itemStatusNew?: boolean;
    itemCountText?: string;
};

const IMG_ALT_TEXT_DISCOVERED = 'A discovered collection item';
const IMG_ALT_TEXT_UNDISCOVERED = 'An undiscovered collection item';

/* TODO (add after MVP):
 * 1. Add ADVANTAGE rareness and all styles for that
 * 2. Add NEW badge (need logic from eagle team)
 * */

export const CollectionItemCard = React.memo(
    ({ isDiscovered, cardName, imageSrc, rarenessIndex, backgroundType, itemCountText }: CollectionItemCardProps) => {
        const imageAlt = isDiscovered ? IMG_ALT_TEXT_DISCOVERED : IMG_ALT_TEXT_UNDISCOVERED;
        const rareness = RarenessIndexTypesMap[rarenessIndex];
        const markCardAsNew = false; // ToDo: check is it current prize, apply in this case
        const cardWrapperClassNames = {
            [styles.discovered]: isDiscovered,
            [styles.discoveredRare]: isDiscovered && rareness === RarenessTypes.RARE,
            [styles.discoveredVeryRare]: isDiscovered && rareness === RarenessTypes.VERY_RARE,
            [styles.discoveredUltraRare]: isDiscovered && rareness === RarenessTypes.ULTRA_RARE,
            [styles.undiscovered]: !isDiscovered,
            [styles.undiscoveredRare]: !isDiscovered && rareness === RarenessTypes.RARE,
            [styles.undiscoveredVeryRare]: !isDiscovered && rareness === RarenessTypes.VERY_RARE,
            [styles.undiscoveredUltraRare]: !isDiscovered && rareness === RarenessTypes.ULTRA_RARE,
            [styles.darkBackground]: backgroundType === BackgroundTypes.DARK,
            [styles.lightBackground]: backgroundType === BackgroundTypes.LIGHT,
            [styles.collectibleCounted]: Boolean(itemCountText),
            [styles.collectibleNew]: markCardAsNew,
        };

        return (
            <div className={styles.container}>
                <div className={classNames(styles.cardWrapper, cardWrapperClassNames)}>
                    <div className={styles.card}>
                        <div className={styles.rarenessBlock}>{rareness}</div>
                        <div className={styles.imageBlock}>
                            <LazyImage img={imageSrc} alt={imageAlt} />
                        </div>
                        {markCardAsNew && <CollectionItemTagNew />}
                    </div>
                    {itemCountText && <CollectionItemCounter itemCountText={itemCountText} />}
                    <div className={styles.cardName}>{cardName}</div>
                </div>
            </div>
        );
    }
);

type CollectionItemCounterProps = {
    itemCountText: string | null;
};

function CollectionItemCounter(props: CollectionItemCounterProps) {
    const { itemCountText } = props;
    const ariaLabel = `You have ${itemCountText} collectibles of this type.`;
    return (
        <div className={styles.itemCounter} aria-label={ariaLabel}>
            {itemCountText}
        </div>
    );
}

function CollectionItemTagNew() {
    return (
        <div className={styles.tagNewBlock}>
            <div className={styles.tagNewLabel}>
                <I18nText
                    className={styles.tagNewText}
                    as={'p'}
                    keyName={tagNewText}
                    params={{ ariaLabel: tagNewAria }}
                />
            </div>
        </div>
    );
}
