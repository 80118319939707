/**
 * Random Number getter
 *
 * @param {number} min
 * @param {number} max
 * @return {number}
 */
export const getRandomIntFromInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
