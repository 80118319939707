export default [
    {
        name: `Family Feud`,
        slug: 'family-feud',
        maxUsers: 400,
        minUsers: 113,
    },
    {
        name: `Arkadium's Bubble Shooter`,
        slug: 'bubble-shooter',
        maxUsers: 400,
        minUsers: 210,
    },
    {
        name: `Word Wipe`,
        slug: 'word-wipe',
        maxUsers: 350,
        minUsers: 139,
    },
    {
        name: `Mahjongg Solitaire`,
        slug: 'mahjongg-solitaire',
        maxUsers: 400,
        minUsers: 200,
    },
    {
        name: `Pool`,
        slug: 'free-8-ball-pool',
        maxUsers: 125,
        minUsers: 60,
    },
    {
        name: `Daily Crossword`,
        slug: 'daily-crossword',
        maxUsers: 150,
        minUsers: 55,
    },
    {
        name: `Mahjongg Dimensions`,
        slug: 'mahjongg-dimensions',
        maxUsers: 400,
        minUsers: 125,
    },
    {
        name: `Mahjong`,
        slug: 'free-mahjong-game',
        maxUsers: 300,
        minUsers: 130,
    },
    {
        name: `Outspell`,
        slug: 'outspell',
        maxUsers: 260,
        minUsers: 80,
    },
    {
        name: `Block Champ`,
        slug: 'block-champ',
        maxUsers: 190,
        minUsers: 70,
    },
    {
        name: `The Daily Jigsaw`,
        slug: 'the-daily-jigsaw',
        maxUsers: 70,
        minUsers: 42,
    },
    {
        name: `Mahjongg Candy`,
        slug: 'mahjongg-candy',
        maxUsers: 170,
        minUsers: 50,
    },
    {
        name: `Arkadium's Texas Hold'em`,
        slug: 'texas-holdem-poker-sit-and-go',
        maxUsers: 100,
        minUsers: 29,
    },
    {
        name: `Classic Solitaire`,
        slug: 'classic-solitaire',
        maxUsers: 110,
        minUsers: 21,
    },
    {
        name: `Jewel Shuffle`,
        slug: 'jewel-shuffle',
        maxUsers: 80,
        minUsers: 40,
    },
];
