import React from 'react';

import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { getLogo } from '../../atoms/PrerollLogo/PrerollLogo';
import { Media } from '../../services/MediaService';
import styles from './PrerollPreloader.css';

const logo = getLogo();

const PrerollPreloader = () => {
    return (
        <>
            <Media lessThan="ARK_SMALL_DESKTOP">
                <div className={styles.preloaderWrapper}>
                    <div className={styles.preloaderContent}>
                        <div className={styles.preloaderContentWrapper}>
                            <div className="ctaSlogan">
                                Your game will begin <br />
                                after the following advertisement
                            </div>
                            <AppLoader className={styles.loader} />
                            <div className="ctaFooter">
                                <div className="ctaLogo" dangerouslySetInnerHTML={{ __html: logo }} />
                                <div className="ctaLogoSlogan">Games you can feel good about™</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Media>
            <Media greaterThan="ARK_SMALL_DESKTOP_BELOW">
                <div
                    style={{
                        position: 'absolute',
                        background: 'rgb(255,255,255)',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        zIndex: 2000,
                        top: 0,
                    }}
                >
                    <div className={styles.preloaderContent}>
                        <div className={styles.preloaderContentWrapper}>
                            <div className="ctaSlogan">
                                Your game will begin <br />
                                after the following advertisement
                            </div>
                            <AppLoader className={styles.loader} />
                            <div className="ctaFooter">
                                <div className="ctaLogo" dangerouslySetInnerHTML={{ __html: logo }} />
                                <div className="ctaLogoSlogan">Games you can feel good about™</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Media>
        </>
    );
};

export default PrerollPreloader;
