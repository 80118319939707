/* eslint-disable jsx-a11y/iframe-has-title */
import React, { RefObject } from 'react';

import styles from './Game.css';

type CanvasProps = {
    renderId: string;
    iframeSourceCode: string | null;
    onIframeLoad: React.ReactEventHandler<HTMLIFrameElement>;
    iframeRef: RefObject<HTMLIFrameElement>;
    isIframeGame: boolean;
};

const GameCanvas = (props: CanvasProps) => {
    const { renderId, iframeSourceCode, onIframeLoad, iframeRef, isIframeGame } = props;

    if (isIframeGame) {
        return (
            <div className={`game ${styles.canvasBoxWrapper}`} aria-hidden="true">
                <iframe
                    src={iframeSourceCode}
                    aria-label={
                        // fixes #147915 - used instead of title that is visible on hover
                        'canvas box'
                    }
                    id="canvas-box"
                    className={`game ${styles.canvasBox}`} // className 'game' is mandatory, since some crosswords disable scroll by this class
                    ref={iframeRef}
                    onLoad={onIframeLoad}
                    loading="lazy"
                />
            </div>
        );
    } else {
        return <div className={`game ${styles.canvasBox}`} aria-hidden="true" id={renderId} />;
    }
};
GameCanvas.displayName = 'GameCanvas';

const MemoizedGameCanvas = React.memo(GameCanvas);
export { MemoizedGameCanvas as GameCanvas };
