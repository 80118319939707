import React, { useEffect, useRef, useState } from 'react';

import { Player } from '@lottiefiles/react-lottie-player';
import classNames from 'classnames';
import { batch, useDispatch, useSelector } from 'react-redux';
import serialize from 'serialize-javascript';

import styles from './GameEnd.css';
import { getAnimData } from '../../../public/animations/gameEndAnim';
import { MiscUtils } from '../../../utils/MiscUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { Responsive } from '../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { SignInUpComponents } from '../../constants/SignInUpComponents';
import { favoritesService } from '../../features/Favorites/FavoritesService';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { GameState, LS_COOKIE_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { UserTopScores } from '../../models/HighScores';
import { UserModel } from '../../models/User/UserModel';
import { Ad, AdTypes, CaptionPositionTypes } from '../../molecules/Ad/Ad';
import { Analytics } from '../../services/Analytics/Analytics';
import { CollectionPrize } from '../../services/CollectionsService';
import { TranslationService } from '../../services/TranslationService';
import { UrlService } from '../../services/UrlService';
import UserService from '../../services/UserService';
import { setActiveGameView, setGameState } from '../../store/ducks/games';
import { setSideMenuActivePage, setSideMenuOpened, setSignInUpState } from '../../store/ducks/layout';
import {
    GemsAnalyticsRegistrationLocations,
    setSocialRegistrationLocation,
} from '../../store/ducks/leanplum/lpAnalytics';
import { GameAppBanner } from '../GameAppBanner/GameAppBanner';

type GameEndProps = {
    score: number;
    game: IGame;
    user: UserModel;
    topScores: UserTopScores | null;
    collectionPrize: CollectionPrize | null;
    onPageReloadHandler: () => void;
    adFree?: boolean;
    subscription?: boolean;
};

const AVERAGE_TEXTS = [
    'GAME_END_AVERAGE_1',
    'GAME_END_AVERAGE_2',
    'GAME_END_AVERAGE_3',
    'GAME_END_AVERAGE_4',
    'GAME_END_AVERAGE_5',
];
const HIGH_SCORE_TEXTS = ['GAME_END_HIGH_1', 'GAME_END_HIGH_2', 'GAME_END_HIGH_3', 'GAME_END_HIGH_4'];

const COLLECTIONS_TEXTS_KEYS = {
    discovered: 'GAME_END_DISCOVERED_TEXT',
    discoveredDuplicate: 'GAME_END_DISCOVERED_DUPLICATE_TEXT',
};

export const GameEnd = React.memo(
    ({ game, subscription, topScores, score, user, adFree, collectionPrize, onPageReloadHandler }: GameEndProps) => {
        const [loginButtonClicked, setLoginButtonClicked] = useState<boolean>(false);
        const [congratsLabel, setCongratsLabel] = useState<string>('');
        const [animationData, setAnimationData] = useState<Object | null>(null);
        const gameEndRef = useRef<HTMLDivElement>(null);
        const dispatch = useDispatch();

        const isDuplicate = collectionPrize?.isDuplicate;

        useEffect(() => {
            Analytics.trackEvent(Analytics.games.playAgainImpression(game));

            if (!collectionPrize) {
                const congratulationsTextKeyValue = getCongratulationsText();
                setCongratsLabel(congratulationsTextKeyValue);
            } else {
                const finalPrizeText = isDuplicate
                    ? COLLECTIONS_TEXTS_KEYS.discoveredDuplicate
                    : COLLECTIONS_TEXTS_KEYS.discovered;
                const prizeImageUrl = ''; // ToDo: user && collectionPrize?.imageSrcEnabled;
                const animDataCustom = getAnimData(
                    TranslationService.translateIt('GAME_END_SCORED').toLocaleUpperCase(),
                    new Intl.NumberFormat('en-US').format(score || 0),
                    TranslationService.translateIt('GAME_END_ANIM_CONGRATULATIONS'),
                    TranslationService.translateIt(finalPrizeText),
                    prizeImageUrl
                );
                setAnimationData({ ...animDataCustom });
            }
        }, [collectionPrize, isDuplicate]);

        /**
         *   Get text key for congrats
         *
         * @return {*}  {string}
         */
        const getCongratulationsText = (): string => {
            const topScore = topScores ? topScores.all : score;
            let arr = score > topScore ? HIGH_SCORE_TEXTS : AVERAGE_TEXTS;
            if (!user) {
                arr = HIGH_SCORE_TEXTS;
            }
            return arr[MiscUtils.random(0, arr.length - 1)];
        };
        /**
         *Play again btn handler
         *
         */
        const onPlayAgainClick = async () => {
            await Analytics.trackEvent(Analytics.games.playAgainClick(game));
            if (subscription && !game.isAdsFree) {
                const dataFromStorage = sessionStorage.getItem(LS_COOKIE_CONSTS.GAME_END_RESTART);
                const gameEndCounter = dataFromStorage ? parseInt(dataFromStorage) : 0;
                //Note: this if-else implementation was created for Fullscreen feature
                if (gameEndCounter > 1) {
                    sessionStorage.setItem(LS_COOKIE_CONSTS.GAME_END_RESTART, '0');
                    onPageReload();
                } else {
                    sessionStorage.setItem(LS_COOKIE_CONSTS.GAME_END_RESTART, (gameEndCounter + 1).toString());
                    dispatch(setGameState(GameState.GAME));
                }
            } else {
                onPageReload();
            }
        };
        /**
         * Page reload handler
         *
         */
        const onPageReload = () => {
            if (window.location.href.indexOf('playAgain') !== -1 || game.isAdsFree) {
                window.location.reload();
            } else {
                const searchParams = new URLSearchParams();
                const previousGame = UrlService.getQSParam(window.location.search, 'recPreviousGame');
                searchParams.append('playAgain', '');
                const oldSearch = window.location.search;
                const oldSearchParams = oldSearch ? `${oldSearch.replace(/^\?/gi, '')}&` : '';
                if (previousGame) searchParams.append('recPreviousGame', previousGame);
                window.location.href = `${
                    window.location.origin + window.location.pathname
                }?${oldSearchParams}${serialize(searchParams.toString()).replaceAll('"', '')}`;
            }
            onPageReloadHandler();
        };
        /**
         * Handler for LOGIN btn
         *
         */
        const onLoginClick = () => {
            if (!loginButtonClicked) {
                Analytics.trackEvent(Analytics.games.saveScoreClick(game));
            }
            setLoginButtonClicked(true);

            batch(() => {
                dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
                dispatch(setSocialRegistrationLocation(GemsAnalyticsRegistrationLocations.GAME_END));
                dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
            });
        };
        /**
         * Handler for main action btn on Game end
         *
         */
        const handleClickMainAction = () => {
            if (user) {
                Analytics.trackEvent(Analytics.games.visitCollection(game));
                dispatch(setActiveGameView({ activeTab: 'collections' }));
            } else {
                Analytics.trackEvent(Analytics.games.saveCollectible(game));
                batch(() => {
                    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
                    dispatch(setSocialRegistrationLocation(GemsAnalyticsRegistrationLocations.COLLECTIONS));
                    dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
                });
            }
        };

        const showCollectionsElements = Boolean(collectionPrize);
        const specialStylesForUnsubscribed = !user && showCollectionsElements;

        // favorites handler
        const userFavoritesList = useSelector((state) => state.userFavoritesList);
        const isGameFavorite = userFavoritesList?.includes?.(game?.slug);
        const toggleFavoriteShow = (setState?) => {
            const isMobile = !MiscUtils.isServer && window.innerWidth <= 1024;
            const isSubscriber = UserService.isUserSubscriber();
            const toggleFavoriteShow = isSubscriber || isMobile;
            if (setState) {
                return setState(toggleFavoriteShow);
            } else {
                return toggleFavoriteShow;
            }
        };
        const [toggleFavoriteShowState, setToggleFavoriteShowState] = useState<boolean>(toggleFavoriteShow());
        const updateToggleFavoriteShowState = () => {
            toggleFavoriteShow(setToggleFavoriteShowState);
        };
        useEffect(() => {
            window.addEventListener('resize', updateToggleFavoriteShowState);
            return () => {
                window.removeEventListener('resize', updateToggleFavoriteShowState);
            };
        });
        const toggleFavoriteTextKey = isGameFavorite && user ? 'FAVORITES_GAME_END_REMOVE' : 'FAVORITES_GAME_END_ADD';
        const toggleFavoriteHandler = async () => {
            if (!user) {
                return batch(() => {
                    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
                    dispatch(setSideMenuOpened(true));
                });
            }
            await favoritesService[isGameFavorite ? 'removeFavorite' : 'addFavorite'](game.slug);
            Analytics.trackEvent(Analytics.favorites.addFavoritesGameEnd({ game, gameFavorited: !isGameFavorite }));
            toggleFavoriteShow();
        };
        const adFreed = adFree || game.isAdsFree;

        return (
            <>
                <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                    <GameAppBanner game={game} />
                </Responsive>
                <div className={classNames(styles.gameEnd, adFreed && styles.freeVersionGameEnd)}>
                    <div className={styles.gameEndContent} ref={gameEndRef}>
                        <div className={styles.scoresContainer}>
                            {!showCollectionsElements && (
                                <>
                                    <I18nText className={styles.subCaption} keyName="GAME_END_SCORED" />
                                    <div className={styles.scores}>
                                        <div className={styles.scoresValue}>
                                            {new Intl.NumberFormat('en-US').format(score || 0)}
                                        </div>
                                    </div>{' '}
                                </>
                            )}
                            {showCollectionsElements && (
                                <div className={styles.animPlayerContainer}>
                                    <Player autoplay keepLastFrame src={animationData || ''} />
                                </div>
                            )}
                            {!showCollectionsElements && <div className={styles.shining} />}
                            {/* TODO: Here should be a animation */}
                            {!showCollectionsElements && (
                                <div className={styles.cheerUp}>
                                    <I18nText keyName={congratsLabel} />
                                </div>
                            )}
                        </div>

                        <div
                            className={classNames(
                                styles.buttons,
                                specialStylesForUnsubscribed && styles.buttonsSpecial,
                                user && styles.userLoginStyle
                            )}
                        >
                            {!showCollectionsElements && !user && (
                                <Button
                                    outlined={!specialStylesForUnsubscribed}
                                    white={!specialStylesForUnsubscribed}
                                    onClick={onLoginClick}
                                    className={classNames(styles.button, styles.loginSaveButton)}
                                >
                                    {' '}
                                    <I18nText keyName="LEADERBOARD_LOGIN_BUTTON_TEXT" />
                                </Button>
                            )}
                            {showCollectionsElements && (
                                <Button
                                    outlined={!specialStylesForUnsubscribed}
                                    white={!specialStylesForUnsubscribed}
                                    className={classNames(styles.button, styles.secondaryBtn)}
                                    onClick={handleClickMainAction}
                                >
                                    {' '}
                                    <I18nText
                                        keyName={
                                            user ? 'COLLECTION_VISIT_MY_COLLECTIONS' : 'COLLECTION_SIGN_IN_TO_SAVE'
                                        }
                                    />
                                </Button>
                            )}
                            <Button
                                white={specialStylesForUnsubscribed}
                                outlined={specialStylesForUnsubscribed}
                                className={classNames(styles.button, styles.playAgainBtn)}
                                onClick={onPlayAgainClick}
                            >
                                {' '}
                                <I18nText keyName="GAME_END_PLAY_AGAIN" />
                            </Button>
                        </div>
                        {favoritesService.isOn && toggleFavoriteShowState && (
                            <button className={styles.favoriteTogglerButton} onClick={toggleFavoriteHandler}>
                                <I18nText keyName={toggleFavoriteTextKey} />
                            </button>
                        )}
                    </div>
                    {!showCollectionsElements && (
                        <>
                            {!adFreed && (
                                <Responsive
                                    minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}
                                    maxWidth={ArkCssBreakpoints.ARK_MEDIUM_LG_DESKTOP_BELOW}
                                >
                                    <div className={styles.adContainer}>
                                        <Ad
                                            id="ark_display_ge1"
                                            hasBackground={false}
                                            captionPosition={CaptionPositionTypes.CENTER}
                                            adType={[AdTypes.AD_320x100, AdTypes.AD_320x50]}
                                        />
                                    </div>
                                </Responsive>
                            )}
                            {!adFreed && (
                                <Responsive
                                    minWidth={ArkCssBreakpoints.ARK_MEDIUM_LG_DESKTOP}
                                    maxWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP_BELOW}
                                >
                                    <div className={styles.adContainer}>
                                        <Ad
                                            id="ark_display_ge1"
                                            hasBackground={false}
                                            captionPosition={CaptionPositionTypes.CENTER}
                                            adType={[AdTypes.AD_728x90]}
                                        />
                                    </div>
                                </Responsive>
                            )}
                            {!adFreed && (
                                <Responsive minWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP}>
                                    <div className={styles.adContainer}>
                                        <Ad
                                            id="ark_display_ge1"
                                            hasBackground={false}
                                            captionPosition={CaptionPositionTypes.CENTER}
                                            adType={[AdTypes.AD_970x250, AdTypes.AD_970x90, AdTypes.AD_728x90]}
                                        />
                                    </div>
                                </Responsive>
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }
);
