import { getRandomIntFromInterval } from '../../helpers/getRandomIntFromInterval';

const nouns = [
    'Ace',
    'Achiever',
    'Admirer',
    'Bird',
    'Bonanza',
    'Bonus',
    'Boss',
    'Buddy',
    'Card',
    'Champ',
    'Chum',
    'Delight',
    'Diamond',
    'Dice',
    'Eagle',
    'Enthusiast',
    'Expert',
    'Fan',
    'Fellow',
    'Friend',
    'Genius',
    'Gift',
    'Giver',
    'Grace',
    'Guide',
    'Heart',
    'Helper',
    'Jester',
    'Jewel',
    'Joker',
    'Juggler',
    'Laughter',
    'Magician',
    'Motivator',
    'Optimist',
    'Passion',
    'Pioneer',
    'Premium',
    'Prize',
    'Rabbit',
    'Sage',
    'Score',
    'Smile',
    'Soul',
    'Spirit',
    'Treasure',
    'VIP',
    'Winner',
    'Wiz',
    'Wonder',
    'Wordsmith',
];
const adjectives = [
    'Amazing',
    'Ambitious',
    'Animated',
    'Assured',
    'Awesome',
    'Best',
    'Bright',
    'Bubbly',
    'Cheerful',
    'Chipper',
    'Classic',
    'Confident',
    'Energized',
    'Eternal',
    'Excellent',
    'Excited',
    'Fierce',
    'Focused',
    'Friendly',
    'Fun',
    'Generous',
    'Great',
    'Happy',
    'Helpful',
    'Incredible',
    'Infinite',
    'Inspired',
    'Inspiring',
    'Jubilant',
    'Kind',
    'Likeable',
    'Loyal',
    'Lucky',
    'Merry',
    'Motivated',
    'Optimistic',
    'Playful',
    'Positive',
    'Quirky',
    'Radiant',
    'Relaxing',
    'Rewarding',
    'Rosy',
    'Sincere',
    'Soothing',
    'Splendid',
    'Super',
    'Upbeat',
    'Welcoming',
    'Witty',
    'Wonderful',
];

/**
 * Generate fake user name
 *
 * @return {string}
 */
export const getUserFakeName = () => {
    const nounsLength = nouns.length;
    const adjectivesLength = adjectives.length;

    const nounsIndex = getRandomIntFromInterval(0, nounsLength - 1);
    const adjectivesIndex = getRandomIntFromInterval(0, adjectivesLength - 1);

    const name = nouns[nounsIndex] + adjectives[adjectivesIndex];
    const number = getRandomIntFromInterval(0, 1000);

    return `${name}${number}`;
};
