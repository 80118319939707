import React, { useCallback, useEffect, useState } from 'react';

import {
  CollectionDto,
  UserCollectionDto
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/collection';
import { useDispatch, useSelector } from 'react-redux';

import styles from './CollectionsTab.css';
import { globalErrorHandler } from '../../../utils/LogUtils';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { BackgroundTypes } from '../../molecules/CollectionItemCard/CollectionItemCard';
import CollectionsService, { MergedCollectionType } from '../../services/CollectionsService';
import { UrlService } from '../../services/UrlService';
import UserService from '../../services/UserService';
import { setUserHasCollectionNotification } from '../../store/ducks/collections/collections';
import { CollectionItemsList } from '../CollectionItemsList/CollectionItemsList';

const enum STATUSES {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  COLLECTIONS_READY = 'COLLECTIONS_READY',
}
const COLLECTION_IMG_SRC = UrlService.toCDNUrl('/collections-tab-img.png');
const ERROR_MESSAGE = 'Sorry, try again later';

export const CollectionsTab = React.memo(() => {
  const [componentStatus, setComponentStatus] = useState<STATUSES>(STATUSES.LOADING);
  const [collectionsToShow, setCollectionsToShow] = useState<MergedCollectionType[] | CollectionDto[] | []>([]);
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const gameArena5Slug = useSelector(({ gameArena5Slug }) => gameArena5Slug);
  const gameName = useSelector(({ gameName }) => gameName);
  const loadGameCollections = useCallback(async () => {
    return CollectionsService.getCollectionListForGame(gameArena5Slug);
  }, [gameArena5Slug]);
  const loadUserCollections = useCallback(async () => {
    let collections: UserCollectionDto[] | [] = [];

    if (UserService.isUserLoggedIn()) {
      collections = await CollectionsService.getUserCollectionsForGame(gameArena5Slug);
    }

    return collections;
  }, [gameArena5Slug]);
  const setUpCollections = useCallback(async () => {
    try {
      const [gameCollectionList, userCollectionList] = await Promise.all([
        loadGameCollections(),
        loadUserCollections()
      ]);
      const newCollectionsToShow =
        userCollectionList.length > 0
          ? CollectionsService.getMergedCollections(gameCollectionList, userCollectionList)
          : gameCollectionList;

      setCollectionsToShow(newCollectionsToShow);
      setComponentStatus(STATUSES.COLLECTIONS_READY);
    } catch (err) {
      globalErrorHandler({ error: err, filename: 'CollectionsTab.tsx', info: 'setUpCollections()' });
      setComponentStatus(STATUSES.ERROR);
    }
  }, [loadGameCollections, loadUserCollections]);

  useEffect(() => {
    dispatch(setUserHasCollectionNotification(false));
  }, [dispatch]);

  useEffect(() => {
    void setUpCollections();
  }, [setUpCollections, user]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <img src={COLLECTION_IMG_SRC} alt="Chest" />
        <p className={styles.headerTitle}>
          <I18nText as="span" keyName="COLLECTION_HEADER_TITLE_DISCOVER" />
          <span>&nbsp;{gameName}</span>
        </p>
      </div>
      {componentStatus === STATUSES.LOADING && (
        <div className={styles.loaderWrapper}>
          <AppLoader />
          <I18nText as="p" keyName="COLLECTION_LOADING_TEXT" />
        </div>
      )}
      {componentStatus === STATUSES.ERROR && (
        <div className={styles.errorWrapper}>
          <p>{ERROR_MESSAGE}</p>
        </div>
      )}
      {componentStatus === STATUSES.COLLECTIONS_READY && (
        <div className={styles.collectionsWrapper}>
          {collectionsToShow.map((collection: MergedCollectionType) => (
            <div className={styles.collectionList} key={collection.name}>
              <CollectionItemsList collection={collection} backgroundType={BackgroundTypes.DARK} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
