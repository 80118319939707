import { QuantcastService } from "./QuantcastService";
import { MiscUtils } from "../../utils/MiscUtils";

class MicrosoftAdvertisingFactory {
    trackGamePlay = () => {
        if (MiscUtils.isServer || !QuantcastService.isAnalyticsEnabled()) {
            return;
        }

        // @ts-ignore
        window.uetq = window.uetq || [];
        // @ts-ignore
        window.uetq.push("event", "ClickPlay", {"event_label":"ClickPlayButton"});
    }

}
export const MicrosoftAdvertisingService = new MicrosoftAdvertisingFactory();