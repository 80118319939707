import React from 'react';

import { iconProps } from '../../../../models/Icon/IconPropsModel';

export const PlayIcon = React.memo((props: iconProps) => {
    return (
        <svg
            aria-label="play-game-icon"
            width="43"
            height="43"
            viewBox="0 0 43 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M28.3333 18.4333L19.4791 13.4647C18.941 13.163 18.3306 13.0041 17.7092 13.004C17.0878 13.0038 16.4773 13.1624 15.939 13.4638C15.4007 13.7651 14.9536 14.1987 14.6425 14.7209C14.3314 15.2432 14.1673 15.8357 14.1666 16.439V26.3762C14.1673 26.9792 14.3312 27.5714 14.642 28.0935C14.9528 28.6155 15.3996 29.049 15.9375 29.3505C16.4759 29.6523 17.0866 29.8112 17.7083 29.8112C18.33 29.8112 18.9407 29.6523 19.4791 29.3505L28.3333 24.3819C28.87 24.0796 29.3155 23.6458 29.6252 23.1238C29.9349 22.6018 30.0979 22.01 30.0979 21.4076C30.0979 20.8052 29.9349 20.2134 29.6252 19.6914C29.3155 19.1694 28.87 18.7356 28.3333 18.4333V18.4333ZM26.5625 21.4076L17.7083 26.3762V16.439L26.5625 21.4076ZM21.25 4.21509C17.7476 4.21509 14.3239 5.22341 11.4117 7.11255C8.49962 9.00169 6.2299 11.6868 4.8896 14.8283C3.5493 17.9698 3.19861 21.4267 3.88189 24.7617C4.56517 28.0967 6.25173 31.1601 8.72828 33.5645C11.2048 35.969 14.3602 37.6064 17.7952 38.2698C21.2303 38.9331 24.7909 38.5927 28.0267 37.2914C31.2624 35.9901 34.0281 33.7865 35.9739 30.9592C37.9197 28.1319 38.9583 24.808 38.9583 21.4076C38.9583 19.1498 38.5003 16.9142 37.6103 14.8283C36.7204 12.7424 35.416 10.8471 33.7716 9.25066C32.1273 7.65418 30.1751 6.38779 28.0267 5.52379C25.8782 4.65979 23.5755 4.21509 21.25 4.21509V4.21509ZM21.25 35.1616C18.4481 35.1616 15.7091 34.3549 13.3794 32.8436C11.0497 31.3323 9.23392 29.1842 8.16167 26.671C7.08943 24.1578 6.80889 21.3923 7.35551 18.7243C7.90213 16.0563 9.25138 13.6056 11.2326 11.682C13.2139 9.75851 15.7381 8.44857 18.4862 7.91787C21.2343 7.38717 24.0827 7.65954 26.6713 8.70055C29.2599 9.74156 31.4725 11.5044 33.0291 13.7663C34.5858 16.0281 35.4166 18.6873 35.4166 21.4076C35.4166 25.0554 33.9241 28.5538 31.2673 31.1331C28.6105 33.7125 25.0072 35.1616 21.25 35.1616V35.1616Z"
                fill="#F9F9F9"
            />
        </svg>
    );
});

export const PauseGameIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="pause-game-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            {...props}
        >
            <g fill="none" fillRule="evenodd">
                <g>
                    <path d="M0 0H34V34H0z" />
                    <circle cx="17" cy="17" r="13" stroke="#FFF" strokeWidth="2" />
                    <g stroke="#FFF" strokeLinecap="round" strokeWidth="2">
                        <path d="M.333 0L.333 10.5M5.667 0L5.667 10.5" transform="translate(14 11.75)" />
                    </g>
                </g>
            </g>
        </svg>
    );
});
