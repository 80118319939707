import React from 'react';

type iconProps = {
    className?: string;
    size?: number;
};

export const CloseIconSmall = React.memo((props: iconProps) => {
    const size = props.size ? props.size : 34;

    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" />
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21.235 12.265l-9.47 9.47m-.215-9.685l9.9 9.9"
                />
            </g>
        </svg>
    );
});
