import React from 'react';

import { GamePageDisplayAdProps } from './GamePageDisplayAdProps';
import { Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';

export const GamePageBottomAd = React.memo((props: GamePageDisplayAdProps) => {
    return (
        <div className={props.className}>
            <Responsive
                minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}
                maxWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP_BELOW}
            >
                <Ad
                    hasBackground
                    keepSize={false}
                    adType={[AdTypes.AD_300x250, AdTypes.AD_250x250]}
                    captionPosition={CaptionPositionTypes.CENTER}
                    id="ark_display_r5"
                    useDefaultBidTimeout
                    refreshRate={0.5}
                    viewableOnly={false}
                />
            </Responsive>
            <Responsive minWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP} maxWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP}>
                <Ad
                    hasBackground
                    keepSize={false}
                    adType={[AdTypes.AD_300x600, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                    captionPosition={CaptionPositionTypes.CENTER}
                    id="ark_display_r5"
                    useDefaultBidTimeout
                    refreshRate={0.5}
                    viewableOnly={false}
                />
            </Responsive>
            <Responsive minWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP_ABOVE}>
                <Ad
                    hasBackground
                    keepSize={false}
                    adType={[
                        AdTypes.AD_300x600,
                        AdTypes.AD_336x280,
                        AdTypes.AD_300x250,
                        AdTypes.AD_250x250,
                        AdTypes.AD_160x600,
                    ]}
                    captionPosition={CaptionPositionTypes.CENTER}
                    id="ark_display_r5"
                    useDefaultBidTimeout
                    refreshRate={0.5}
                    viewableOnly={false}
                />
            </Responsive>
        </div>
    );
});
