import React, { Suspense } from 'react';

import { OpenWebConversation } from "./OpenWebConversation";
import { OpenWebProviderComponent } from "./OpenWebProviderComponent";
import { MiscUtils } from '../../../utils/MiscUtils';
import { IGame } from '../../models/Game/Game';

const OpenWebConversationsBlock = ({ topicId, game, linkPath }: { topicId: string; game?: IGame; linkPath: string }) =>
    !MiscUtils.isServer && (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <OpenWebConversation topicId={topicId} game={game} linkPath={linkPath} />
            </Suspense>
        </>
    );
const OpenWebProviderBlock = ({ topicId, game }: { topicId; game?: IGame }) =>
    !MiscUtils.isServer && (
        <Suspense fallback={<div>Loading...</div>}>
            <OpenWebProviderComponent topicId={topicId} game={game} />
        </Suspense>
    );

export const OpenWebBlock = ({ topicId, game, linkPath }: { topicId: string; game?: IGame; linkPath: string }) => (
    <>
        <div data-spotim-module="pitc"></div>
        <OpenWebConversationsBlock topicId={topicId} game={game} linkPath={linkPath} />
        <OpenWebProviderBlock topicId={topicId} game={game} />
    </>
);
