export enum GameUserEventsNames {
    HURDLE_ENTER_TRY = 'EnterTry',
    HURDLE_ENTER_SECOND_INVALID_TRY = 'EnterSecondInvalidTry',
}

export enum GameUserEventsResults {
    SUCCESS = 'Success',
    FAIL = 'Fail',
}

interface GameUserEventsPayload {
    event_name: GameUserEventsNames;
    payload: any;
}

export interface EnterTryPayload extends GameUserEventsPayload {
    event_name: GameUserEventsNames.HURDLE_ENTER_TRY;
    payload: {
        Reason: GameUserEventsResults;
        EnterTryN: number;
        greenGuessed: number;
    };
}

export interface EnterSecondInvalidTry extends GameUserEventsPayload {
    event_name: GameUserEventsNames.HURDLE_ENTER_SECOND_INVALID_TRY;
    payload: {
        Reason: GameUserEventsResults;
        EnterTryN: number;
        greenGuessed: number;
        yellowGuessed: number;
        enteredWord: string;
    };
}
