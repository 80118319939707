import React from 'react';

type iconProps = {
    className?: string;
    isFullCollection?: boolean;
};

export const CollectionsIcon = React.memo(({ className, isFullCollection = false }: iconProps) => {
    const defaultStyle = (
        <svg
            className={className}
            role="img"
            aria-label="collections-icon"
            width="31"
            height="25"
            viewBox="0 0 31 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.375 11.0416V4.29163C2.375 3.18706 3.27043 2.29163 4.375 2.29163H26.625C27.7296 2.29163 28.625 3.18706 28.625 4.29163V11.0416M2.375 11.0416V22.2083C2.375 22.4844 2.59886 22.7083 2.875 22.7083H28.125C28.4011 22.7083 28.625 22.4844 28.625 22.2083V11.0416M2.375 11.0416H12.5833M12.5833 11.0416V9.12496C12.5833 8.57268 13.031 8.12496 13.5833 8.12496H17.4167C17.969 8.12496 18.4167 8.57267 18.4167 9.12496V11.0416M12.5833 11.0416V12.9583C12.5833 13.5106 13.031 13.9583 13.5833 13.9583H17.4167C17.969 13.9583 18.4167 13.5106 18.4167 12.9583V11.0416M18.4167 11.0416H28.625"
                stroke="#F9F9F9"
                strokeWidth="3"
            />
        </svg>
    );

    const fullCollectionStyle = (
        <svg
            className={className}
            role="img"
            aria-label="full-collection-icon"
            width="24"
            height="37"
            viewBox="0 0 24 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="path-1-outside-1_35_2283"
                maskUnits="userSpaceOnUse"
                x="2"
                y="0"
                width="20"
                height="19"
                fill="black"
            >
                <rect fill="white" x="2" width="20" height="19" />
                <path d="M11.8419 8.95781C12.1137 8.95781 12.3744 9.06366 12.5666 9.25204C12.7588 9.44053 12.8668 9.69608 12.8668 9.96249C12.8667 10.3057 12.8486 10.6488 12.8127 10.9903L12.0969 17.7739C12.0837 17.9023 11.9735 17.9999 11.8419 17.9999C11.7104 17.9999 11.6001 17.9023 11.587 17.7739L10.8711 10.9894C10.8351 10.6482 10.8172 10.3054 10.8171 9.96243C10.8171 9.69602 10.9251 9.44046 11.1172 9.25198C11.3095 9.0636 11.5702 8.95781 11.8419 8.95781ZM11.3807 10.9386L11.8419 15.3081L12.3031 10.9377C12.3372 10.6137 12.3542 10.2882 12.3543 9.96241C12.3543 9.78289 12.2567 9.61705 12.0981 9.52735C11.9396 9.43765 11.7442 9.43765 11.5857 9.52735C11.4272 9.61705 11.3295 9.78289 11.3295 9.96241C11.3295 10.2884 11.3466 10.6143 11.3807 10.9386Z" />
                <path d="M6.87131 12.1225C6.65055 11.9775 6.50426 11.7464 6.46959 11.4883C6.43505 11.2301 6.51546 10.9697 6.69046 10.7738C6.86547 10.5778 7.11803 10.4652 7.38373 10.4648H7.46472C7.70983 10.4641 7.94499 10.5596 8.11784 10.7299C8.81304 11.4075 9.36435 12.2135 9.73977 13.1012C10.115 13.9891 10.3071 14.9411 10.3046 15.9021V17.7487C10.3046 17.8768 10.2062 17.9844 10.0763 17.9984C10.067 17.9993 10.0578 17.9998 10.0484 17.9998C9.92838 17.9997 9.82464 17.918 9.79856 17.8032L9.29747 15.5929C8.97824 14.1715 8.10834 12.9273 6.87131 12.1225ZM9.77167 15.3754C9.64791 13.7544 8.93258 12.2313 7.75675 11.0851C7.67989 11.0092 7.57512 10.9667 7.466 10.967H7.38375C7.20246 10.9669 7.04257 11.0833 6.99007 11.2534C6.93756 11.4235 7.00482 11.6073 7.15569 11.7057C8.46981 12.5605 9.40451 13.8717 9.77167 15.3754Z" />
                <path d="M16.2179 10.4648H16.2988C16.5644 10.4652 16.8171 10.5778 16.9921 10.7738C17.1671 10.9697 17.2475 11.2301 17.213 11.4883C17.1783 11.7464 17.0319 11.9775 16.8113 12.1225C15.5752 12.9271 14.706 14.1707 14.3867 15.5912L13.8856 17.8015C13.8604 17.9171 13.756 17.9998 13.6355 18C13.6261 18 13.6166 17.9996 13.6073 17.9985C13.4775 17.9844 13.3793 17.8769 13.3793 17.7488V15.9023C13.3766 14.9413 13.5685 13.9893 13.9435 13.1016C14.3187 12.2138 14.8698 11.4078 15.5648 10.7301C15.7376 10.5598 15.9727 10.4641 16.2179 10.4648ZM16.5268 11.7058C16.6778 11.6073 16.7451 11.4236 16.6926 11.2535C16.64 11.0834 16.4801 10.967 16.2989 10.9671H16.2179C16.1088 10.9667 16.004 11.0092 15.9271 11.0852C14.7513 12.2313 14.0359 13.7544 13.9122 15.3754C14.279 13.8721 15.2133 12.5609 16.5268 11.7058Z" />
                <path d="M10.7913 7.05437L9.89452 6.39584C9.81377 6.33653 9.77614 6.23628 9.79821 6.13985C9.82029 6.0433 9.89818 5.96851 9.99701 5.94878L11.1417 5.72396L11.6029 4.5937C11.6418 4.49816 11.7361 4.43548 11.841 4.43548C11.9458 4.43548 12.0402 4.49816 12.079 4.5937L12.5402 5.72396L13.6865 5.94878C13.7853 5.96852 13.8632 6.04331 13.8853 6.13985C13.9073 6.23628 13.8697 6.33653 13.789 6.39584L12.8922 7.05437L13.1177 8.15403C13.1392 8.26235 13.0861 8.37179 12.9868 8.4237C12.8875 8.47562 12.7652 8.4579 12.6854 8.38009L11.8418 7.5547L10.9981 8.38175C10.9183 8.45957 10.796 8.4774 10.6967 8.42537C10.5974 8.37345 10.5443 8.26413 10.5658 8.15581L10.7913 7.05437ZM11.2268 6.74748C11.3061 6.80567 11.344 6.90356 11.3242 6.99865L11.234 7.44122L11.6607 7.02299C11.7608 6.92499 11.9228 6.92499 12.0229 7.02299L12.4495 7.44122L12.3593 6.99865C12.3395 6.90356 12.3774 6.80567 12.4566 6.74748L13.0232 6.33103L12.3029 6.18985C12.2179 6.17326 12.1474 6.11574 12.1151 6.03691L11.8417 5.36403L11.5673 6.03691C11.5351 6.11574 11.4645 6.17326 11.3795 6.18985L10.6603 6.33103L11.2268 6.74748Z" />
                <path d="M20.0007 8.59149L20.2261 9.69205C20.2476 9.80036 20.1946 9.9098 20.0953 9.96172C19.996 10.0136 19.8737 9.99592 19.7939 9.9181L18.9502 9.09182L18.1066 9.91887C18.0267 9.99669 17.9045 10.0144 17.8052 9.96249C17.7059 9.91046 17.6528 9.80114 17.6743 9.69282L17.8998 8.59149L17.003 7.93262C16.9223 7.8733 16.8846 7.77306 16.9067 7.67663C16.9288 7.58008 17.0067 7.50529 17.1055 7.48556L18.2502 7.26074L18.7114 6.13048C18.7503 6.03494 18.8446 5.97226 18.9494 5.97226C19.0543 5.97226 19.1487 6.03494 19.1875 6.13048L19.6487 7.26074L20.795 7.48578C20.8938 7.50552 20.9717 7.58031 20.9938 7.67685C21.0158 7.77328 20.9782 7.87353 20.8975 7.93284L20.0007 8.59149ZM19.4114 7.72698C19.3264 7.71049 19.2558 7.65275 19.2236 7.57392L18.9502 6.90104L18.6758 7.57392C18.6436 7.65275 18.573 7.71038 18.488 7.72686L17.7688 7.86804L18.3353 8.28426C18.4146 8.34246 18.4525 8.44035 18.4327 8.53543L18.3425 8.97778L18.7691 8.55909C18.8691 8.46097 19.0313 8.46097 19.1314 8.55909L19.5579 8.977L19.4678 8.53543C19.448 8.44035 19.4859 8.34246 19.5651 8.28426L20.1317 7.86804L19.4114 7.72698Z" />
                <path d="M3.20494 7.41159L4.34967 7.18678L4.81086 6.05651C4.84975 5.96098 4.944 5.8983 5.04889 5.8983C5.15377 5.8983 5.24814 5.96098 5.28691 6.05651L5.7481 7.18678L6.89283 7.41159C6.99165 7.43133 7.06955 7.50612 7.09174 7.60266C7.11381 7.69909 7.07607 7.79934 6.99543 7.85866L6.09868 8.51718L6.3231 9.61785C6.3446 9.72617 6.29153 9.8356 6.19225 9.88752C6.09285 9.93944 5.97057 9.92172 5.89085 9.8439L5.04968 9.01763L4.20602 9.84468C4.12618 9.9225 4.00391 9.94021 3.90462 9.8883C3.80534 9.83627 3.75227 9.72694 3.77377 9.61863L3.99922 8.51729L3.10247 7.85842C3.02172 7.79911 2.98408 7.69886 3.00616 7.60243C3.02823 7.50589 3.10612 7.43133 3.20494 7.41159ZM4.43477 8.21006C4.51403 8.26825 4.55189 8.36614 4.53211 8.46123L4.44197 8.90357L4.86861 8.48566C4.96869 8.38765 5.13077 8.38754 5.23085 8.48554L5.65748 8.90346L5.56724 8.46123C5.54745 8.36614 5.58531 8.26825 5.66457 8.21006L6.23111 7.79361L5.5119 7.65243C5.42692 7.63584 5.35634 7.57832 5.32408 7.49949L5.04968 6.82681L4.77528 7.49969C4.74303 7.57852 4.67246 7.63615 4.58747 7.65263L3.86826 7.79381L4.43477 8.21006Z" />
                <path d="M16.319 4.94517H15.8066V4.81578C15.8047 4.1069 15.9462 3.40463 16.223 2.74967C16.4997 2.09461 16.9062 1.49999 17.4189 1L17.7813 1.35523C17.3162 1.80857 16.9476 2.34791 16.6966 2.94196C16.4456 3.5359 16.3173 4.17281 16.319 4.81575V4.94517Z" />
                <path d="M7.53895 4.84258C7.54066 4.19964 7.41244 3.56274 7.16136 2.9688C6.91041 2.37474 6.54177 1.8354 6.07669 1.38206L6.43905 1.02684C6.95182 1.52682 7.35834 2.12145 7.63502 2.7765C7.91182 3.43144 8.05331 4.13371 8.05138 4.84261V4.97201H7.53895V4.84258Z" />
                <path d="M15.9413 14.7349V14.2326H16.4356C17.6641 14.2293 18.8431 14.708 19.7095 15.562L19.3472 15.9172C18.5767 15.1578 17.5283 14.732 16.4356 14.7349L15.9413 14.7349Z" />
                <path d="M7.74245 14.2326V14.7349H7.24821C6.15554 14.732 5.10711 15.1577 4.33663 15.9172L3.97427 15.562C4.84071 14.708 6.01964 14.2293 7.24824 14.2326L7.74245 14.2326Z" />
                <path d="M7.17065 13.3178L6.77708 13.6393L6.48013 13.2901C5.90595 12.6127 5.05391 12.2215 4.15556 12.2227V11.7204C5.20591 11.719 6.20228 12.1765 6.8737 12.9685L7.17065 13.3178Z" />
                <path d="M19.5283 12.2227C18.63 12.2215 17.7779 12.6128 17.2037 13.2902L16.9067 13.6393L16.5131 13.3179L16.8101 12.9685C17.4815 12.1765 18.478 11.719 19.5283 11.7204L19.5283 12.2227Z" />
                <path d="M15.4291 9.46019C15.4291 9.66 15.3481 9.85176 15.204 9.99301C15.0599 10.1343 14.8643 10.2137 14.6605 10.2137C14.4567 10.2137 14.261 10.1343 14.1169 9.99301C13.9728 9.85173 13.8918 9.65998 13.8918 9.46019C13.8918 9.2604 13.9728 9.06862 14.1169 8.92736C14.2611 8.78611 14.4567 8.70669 14.6605 8.70669C14.8643 8.70669 15.0599 8.78608 15.204 8.92736C15.3481 9.06865 15.4291 9.2604 15.4291 9.46019ZM14.6604 9.20902C14.5568 9.20902 14.4634 9.27024 14.4238 9.36409C14.3841 9.45794 14.4059 9.56592 14.4793 9.6378C14.5526 9.70968 14.6627 9.73109 14.7585 9.69218C14.8542 9.65339 14.9167 9.56178 14.9167 9.46019C14.9167 9.32148 14.8019 9.20902 14.6604 9.20902Z" />
                <path d="M18.9943 4.76117C18.7905 4.76117 18.5949 4.68179 18.4508 4.5405C18.3067 4.39922 18.2257 4.20747 18.2257 4.00768C18.2257 3.80789 18.3067 3.61611 18.4508 3.47485C18.5949 3.3336 18.7905 3.25418 18.9943 3.25418C19.1981 3.25418 19.3937 3.33357 19.5378 3.47485C19.6819 3.61614 19.7629 3.80789 19.7629 4.00768C19.7629 4.20747 19.6819 4.39925 19.5378 4.5405C19.3937 4.68176 19.1981 4.76117 18.9943 4.76117ZM18.9943 3.75649C18.8907 3.75649 18.7972 3.81772 18.7576 3.91157C18.718 4.00542 18.7398 4.1134 18.8131 4.18528C18.8864 4.25715 18.9966 4.27857 19.0923 4.23966C19.1881 4.20086 19.2505 4.10925 19.2505 4.00766C19.2505 3.86896 19.1358 3.75649 18.9943 3.75649Z" />
                <path d="M4.84901 4.76117C4.64519 4.76117 4.44958 4.68179 4.30549 4.5405C4.16139 4.39922 4.08039 4.20747 4.08039 4.00768C4.08039 3.80789 4.16137 3.61611 4.30549 3.47485C4.44961 3.3336 4.64521 3.25418 4.84901 3.25418C5.05281 3.25418 5.24844 3.33357 5.39254 3.47485C5.53663 3.61614 5.61764 3.80789 5.61764 4.00768C5.61764 4.20747 5.53666 4.39925 5.39254 4.5405C5.24842 4.68176 5.05281 4.76117 4.84901 4.76117ZM4.84901 3.75649C4.74538 3.75649 4.65193 3.81772 4.61236 3.91157C4.57267 4.00542 4.59452 4.1134 4.66783 4.18528C4.74115 4.25715 4.8513 4.27857 4.94704 4.23966C5.04277 4.20086 5.10523 4.10925 5.10523 4.00766C5.10523 3.86896 4.9905 3.75649 4.84901 3.75649Z" />
                <path d="M9.7924 9.46019C9.7924 9.66 9.71142 9.85176 9.5673 9.99301C9.42318 10.1343 9.22758 10.2137 9.02378 10.2137C8.81998 10.2137 8.62435 10.1343 8.48025 9.99301C8.33616 9.85173 8.25515 9.65998 8.25515 9.46019C8.25515 9.2604 8.33613 9.06862 8.48025 8.92736C8.62438 8.78611 8.81998 8.70669 9.02378 8.70669C9.22758 8.70669 9.42321 8.78608 9.5673 8.92736C9.7114 9.06865 9.7924 9.2604 9.7924 9.46019ZM9.02376 9.20902C8.92013 9.20902 8.82668 9.27024 8.78711 9.36409C8.74742 9.45794 8.76927 9.56592 8.84258 9.6378C8.9159 9.70968 9.02605 9.73109 9.12179 9.69218C9.21752 9.65339 9.27998 9.56178 9.27998 9.46019C9.27998 9.32148 9.16525 9.20902 9.02376 9.20902Z" />
                <path d="M7.99872 15.4879C8.20255 15.4879 8.39815 15.5673 8.54225 15.7086C8.68634 15.8498 8.76735 16.0416 8.76735 16.2414C8.76735 16.4412 8.68637 16.6329 8.54225 16.7742C8.39813 16.9155 8.20252 16.9949 7.99872 16.9949C7.79492 16.9949 7.59929 16.9155 7.4552 16.7742C7.3111 16.6329 7.2301 16.4412 7.2301 16.2414C7.2301 16.0416 7.31108 15.8498 7.4552 15.7086C7.59932 15.5673 7.79492 15.4879 7.99872 15.4879ZM7.99872 16.4926C8.10235 16.4926 8.1958 16.4313 8.23538 16.3375C8.27507 16.2436 8.25322 16.1357 8.1799 16.0638C8.10658 15.9919 7.99644 15.9705 7.9007 16.0094C7.80496 16.0482 7.74251 16.1398 7.74251 16.2414C7.74251 16.308 7.7695 16.3719 7.81754 16.419C7.86558 16.4661 7.93078 16.4926 7.99872 16.4926Z" />
                <path d="M15.6854 15.4879C15.8892 15.4879 16.0848 15.5673 16.2289 15.7086C16.373 15.8498 16.454 16.0416 16.454 16.2414C16.454 16.4412 16.373 16.6329 16.2289 16.7742C16.0848 16.9155 15.8892 16.9949 15.6854 16.9949C15.4816 16.9949 15.2859 16.9155 15.1418 16.7742C14.9977 16.6329 14.9167 16.4412 14.9167 16.2414C14.9167 16.0416 14.9977 15.8498 15.1418 15.7086C15.286 15.5673 15.4816 15.4879 15.6854 15.4879ZM15.6854 16.4926C15.789 16.4926 15.8824 16.4313 15.922 16.3375C15.9617 16.2436 15.9399 16.1357 15.8665 16.0638C15.7932 15.9919 15.6831 15.9705 15.5873 16.0094C15.4916 16.0482 15.4291 16.1398 15.4291 16.2414C15.4291 16.308 15.4561 16.3719 15.5042 16.419C15.5522 16.4661 15.6174 16.4926 15.6854 16.4926Z" />
                <path d="M13.3793 3.39371H12.8669C12.8691 2.52461 13.2862 1.70662 13.9942 1.18342L14.3017 1.58529C13.7225 2.01328 13.3811 2.6826 13.3793 3.39371Z" />
                <path d="M9.28882 1.54897L9.59627 1.1471C10.3043 1.67031 10.7214 2.48829 10.7236 3.35739H10.2112C10.2095 2.64628 9.86804 1.97696 9.28882 1.54897Z" />
                <path d="M14.5852 4.76117L14.0729 4.25895L13.7106 4.61418L14.2229 5.1164L14.5852 4.76117Z" />
                <path d="M9.97329 4.61418L9.61093 4.25895L9.09862 4.76117L9.46098 5.1164L9.97329 4.61418Z" />
                <path d="M16.2988 6.19386L16.0146 5.77586L15.2458 6.27832L15.5301 6.69632L16.2988 6.19386Z" />
                <path d="M8.46414 6.40075L7.69538 5.8983L7.41114 6.3163L8.1799 6.81876L8.46414 6.40075Z" />
                <path d="M6.38623 16.4191L6.02388 16.0639L5.25512 16.8175L5.61748 17.1728L6.38623 16.4191Z" />
                <path d="M18.4284 16.8172L17.6596 16.0636L17.2973 16.4188L18.066 17.1724L18.4284 16.8172Z" />
            </mask>
            <path
                d="M11.8419 8.95781C12.1137 8.95781 12.3744 9.06366 12.5666 9.25204C12.7588 9.44053 12.8668 9.69608 12.8668 9.96249C12.8667 10.3057 12.8486 10.6488 12.8127 10.9903L12.0969 17.7739C12.0837 17.9023 11.9735 17.9999 11.8419 17.9999C11.7104 17.9999 11.6001 17.9023 11.587 17.7739L10.8711 10.9894C10.8351 10.6482 10.8172 10.3054 10.8171 9.96243C10.8171 9.69602 10.9251 9.44046 11.1172 9.25198C11.3095 9.0636 11.5702 8.95781 11.8419 8.95781ZM11.3807 10.9386L11.8419 15.3081L12.3031 10.9377C12.3372 10.6137 12.3542 10.2882 12.3543 9.96241C12.3543 9.78289 12.2567 9.61705 12.0981 9.52735C11.9396 9.43765 11.7442 9.43765 11.5857 9.52735C11.4272 9.61705 11.3295 9.78289 11.3295 9.96241C11.3295 10.2884 11.3466 10.6143 11.3807 10.9386Z"
                fill="#F8D15C"
            />
            <path
                d="M6.87131 12.1225C6.65055 11.9775 6.50426 11.7464 6.46959 11.4883C6.43505 11.2301 6.51546 10.9697 6.69046 10.7738C6.86547 10.5778 7.11803 10.4652 7.38373 10.4648H7.46472C7.70983 10.4641 7.94499 10.5596 8.11784 10.7299C8.81304 11.4075 9.36435 12.2135 9.73977 13.1012C10.115 13.9891 10.3071 14.9411 10.3046 15.9021V17.7487C10.3046 17.8768 10.2062 17.9844 10.0763 17.9984C10.067 17.9993 10.0578 17.9998 10.0484 17.9998C9.92838 17.9997 9.82464 17.918 9.79856 17.8032L9.29747 15.5929C8.97824 14.1715 8.10834 12.9273 6.87131 12.1225ZM9.77167 15.3754C9.64791 13.7544 8.93258 12.2313 7.75675 11.0851C7.67989 11.0092 7.57512 10.9667 7.466 10.967H7.38375C7.20246 10.9669 7.04257 11.0833 6.99007 11.2534C6.93756 11.4235 7.00482 11.6073 7.15569 11.7057C8.46981 12.5605 9.40451 13.8717 9.77167 15.3754Z"
                fill="#F8D15C"
            />
            <path
                d="M16.2179 10.4648H16.2988C16.5644 10.4652 16.8171 10.5778 16.9921 10.7738C17.1671 10.9697 17.2475 11.2301 17.213 11.4883C17.1783 11.7464 17.0319 11.9775 16.8113 12.1225C15.5752 12.9271 14.706 14.1707 14.3867 15.5912L13.8856 17.8015C13.8604 17.9171 13.756 17.9998 13.6355 18C13.6261 18 13.6166 17.9996 13.6073 17.9985C13.4775 17.9844 13.3793 17.8769 13.3793 17.7488V15.9023C13.3766 14.9413 13.5685 13.9893 13.9435 13.1016C14.3187 12.2138 14.8698 11.4078 15.5648 10.7301C15.7376 10.5598 15.9727 10.4641 16.2179 10.4648ZM16.5268 11.7058C16.6778 11.6073 16.7451 11.4236 16.6926 11.2535C16.64 11.0834 16.4801 10.967 16.2989 10.9671H16.2179C16.1088 10.9667 16.004 11.0092 15.9271 11.0852C14.7513 12.2313 14.0359 13.7544 13.9122 15.3754C14.279 13.8721 15.2133 12.5609 16.5268 11.7058Z"
                fill="#F8D15C"
            />
            <path
                d="M10.7913 7.05437L9.89452 6.39584C9.81377 6.33653 9.77614 6.23628 9.79821 6.13985C9.82029 6.0433 9.89818 5.96851 9.99701 5.94878L11.1417 5.72396L11.6029 4.5937C11.6418 4.49816 11.7361 4.43548 11.841 4.43548C11.9458 4.43548 12.0402 4.49816 12.079 4.5937L12.5402 5.72396L13.6865 5.94878C13.7853 5.96852 13.8632 6.04331 13.8853 6.13985C13.9073 6.23628 13.8697 6.33653 13.789 6.39584L12.8922 7.05437L13.1177 8.15403C13.1392 8.26235 13.0861 8.37179 12.9868 8.4237C12.8875 8.47562 12.7652 8.4579 12.6854 8.38009L11.8418 7.5547L10.9981 8.38175C10.9183 8.45957 10.796 8.4774 10.6967 8.42537C10.5974 8.37345 10.5443 8.26413 10.5658 8.15581L10.7913 7.05437ZM11.2268 6.74748C11.3061 6.80567 11.344 6.90356 11.3242 6.99865L11.234 7.44122L11.6607 7.02299C11.7608 6.92499 11.9228 6.92499 12.0229 7.02299L12.4495 7.44122L12.3593 6.99865C12.3395 6.90356 12.3774 6.80567 12.4566 6.74748L13.0232 6.33103L12.3029 6.18985C12.2179 6.17326 12.1474 6.11574 12.1151 6.03691L11.8417 5.36403L11.5673 6.03691C11.5351 6.11574 11.4645 6.17326 11.3795 6.18985L10.6603 6.33103L11.2268 6.74748Z"
                fill="#F8D15C"
            />
            <path
                d="M20.0007 8.59149L20.2261 9.69205C20.2476 9.80036 20.1946 9.9098 20.0953 9.96172C19.996 10.0136 19.8737 9.99592 19.7939 9.9181L18.9502 9.09182L18.1066 9.91887C18.0267 9.99669 17.9045 10.0144 17.8052 9.96249C17.7059 9.91046 17.6528 9.80114 17.6743 9.69282L17.8998 8.59149L17.003 7.93262C16.9223 7.8733 16.8846 7.77306 16.9067 7.67663C16.9288 7.58008 17.0067 7.50529 17.1055 7.48556L18.2502 7.26074L18.7114 6.13048C18.7503 6.03494 18.8446 5.97226 18.9494 5.97226C19.0543 5.97226 19.1487 6.03494 19.1875 6.13048L19.6487 7.26074L20.795 7.48578C20.8938 7.50552 20.9717 7.58031 20.9938 7.67685C21.0158 7.77328 20.9782 7.87353 20.8975 7.93284L20.0007 8.59149ZM19.4114 7.72698C19.3264 7.71049 19.2558 7.65275 19.2236 7.57392L18.9502 6.90104L18.6758 7.57392C18.6436 7.65275 18.573 7.71038 18.488 7.72686L17.7688 7.86804L18.3353 8.28426C18.4146 8.34246 18.4525 8.44035 18.4327 8.53543L18.3425 8.97778L18.7691 8.55909C18.8691 8.46097 19.0313 8.46097 19.1314 8.55909L19.5579 8.977L19.4678 8.53543C19.448 8.44035 19.4859 8.34246 19.5651 8.28426L20.1317 7.86804L19.4114 7.72698Z"
                fill="#F8D15C"
            />
            <path
                d="M3.20494 7.41159L4.34967 7.18678L4.81086 6.05651C4.84975 5.96098 4.944 5.8983 5.04889 5.8983C5.15377 5.8983 5.24814 5.96098 5.28691 6.05651L5.7481 7.18678L6.89283 7.41159C6.99165 7.43133 7.06955 7.50612 7.09174 7.60266C7.11381 7.69909 7.07607 7.79934 6.99543 7.85866L6.09868 8.51718L6.3231 9.61785C6.3446 9.72617 6.29153 9.8356 6.19225 9.88752C6.09285 9.93944 5.97057 9.92172 5.89085 9.8439L5.04968 9.01763L4.20602 9.84468C4.12618 9.9225 4.00391 9.94021 3.90462 9.8883C3.80534 9.83627 3.75227 9.72694 3.77377 9.61863L3.99922 8.51729L3.10247 7.85842C3.02172 7.79911 2.98408 7.69886 3.00616 7.60243C3.02823 7.50589 3.10612 7.43133 3.20494 7.41159ZM4.43477 8.21006C4.51403 8.26825 4.55189 8.36614 4.53211 8.46123L4.44197 8.90357L4.86861 8.48566C4.96869 8.38765 5.13077 8.38754 5.23085 8.48554L5.65748 8.90346L5.56724 8.46123C5.54745 8.36614 5.58531 8.26825 5.66457 8.21006L6.23111 7.79361L5.5119 7.65243C5.42692 7.63584 5.35634 7.57832 5.32408 7.49949L5.04968 6.82681L4.77528 7.49969C4.74303 7.57852 4.67246 7.63615 4.58747 7.65263L3.86826 7.79381L4.43477 8.21006Z"
                fill="#F8D15C"
            />
            <path
                d="M16.319 4.94517H15.8066V4.81578C15.8047 4.1069 15.9462 3.40463 16.223 2.74967C16.4997 2.09461 16.9062 1.49999 17.4189 1L17.7813 1.35523C17.3162 1.80857 16.9476 2.34791 16.6966 2.94196C16.4456 3.5359 16.3173 4.17281 16.319 4.81575V4.94517Z"
                fill="#F8D15C"
            />
            <path
                d="M7.53895 4.84258C7.54066 4.19964 7.41244 3.56274 7.16136 2.9688C6.91041 2.37474 6.54177 1.8354 6.07669 1.38206L6.43905 1.02684C6.95182 1.52682 7.35834 2.12145 7.63502 2.7765C7.91182 3.43144 8.05331 4.13371 8.05138 4.84261V4.97201H7.53895V4.84258Z"
                fill="#F8D15C"
            />
            <path
                d="M15.9413 14.7349V14.2326H16.4356C17.6641 14.2293 18.8431 14.708 19.7095 15.562L19.3472 15.9172C18.5767 15.1578 17.5283 14.732 16.4356 14.7349L15.9413 14.7349Z"
                fill="#F8D15C"
            />
            <path
                d="M7.74245 14.2326V14.7349H7.24821C6.15554 14.732 5.10711 15.1577 4.33663 15.9172L3.97427 15.562C4.84071 14.708 6.01964 14.2293 7.24824 14.2326L7.74245 14.2326Z"
                fill="#F8D15C"
            />
            <path
                d="M7.17065 13.3178L6.77708 13.6393L6.48013 13.2901C5.90595 12.6127 5.05391 12.2215 4.15556 12.2227V11.7204C5.20591 11.719 6.20228 12.1765 6.8737 12.9685L7.17065 13.3178Z"
                fill="#F8D15C"
            />
            <path
                d="M19.5283 12.2227C18.63 12.2215 17.7779 12.6128 17.2037 13.2902L16.9067 13.6393L16.5131 13.3179L16.8101 12.9685C17.4815 12.1765 18.478 11.719 19.5283 11.7204L19.5283 12.2227Z"
                fill="#F8D15C"
            />
            <path
                d="M15.4291 9.46019C15.4291 9.66 15.3481 9.85176 15.204 9.99301C15.0599 10.1343 14.8643 10.2137 14.6605 10.2137C14.4567 10.2137 14.261 10.1343 14.1169 9.99301C13.9728 9.85173 13.8918 9.65998 13.8918 9.46019C13.8918 9.2604 13.9728 9.06862 14.1169 8.92736C14.2611 8.78611 14.4567 8.70669 14.6605 8.70669C14.8643 8.70669 15.0599 8.78608 15.204 8.92736C15.3481 9.06865 15.4291 9.2604 15.4291 9.46019ZM14.6604 9.20902C14.5568 9.20902 14.4634 9.27024 14.4238 9.36409C14.3841 9.45794 14.4059 9.56592 14.4793 9.6378C14.5526 9.70968 14.6627 9.73109 14.7585 9.69218C14.8542 9.65339 14.9167 9.56178 14.9167 9.46019C14.9167 9.32148 14.8019 9.20902 14.6604 9.20902Z"
                fill="#F8D15C"
            />
            <path
                d="M18.9943 4.76117C18.7905 4.76117 18.5949 4.68179 18.4508 4.5405C18.3067 4.39922 18.2257 4.20747 18.2257 4.00768C18.2257 3.80789 18.3067 3.61611 18.4508 3.47485C18.5949 3.3336 18.7905 3.25418 18.9943 3.25418C19.1981 3.25418 19.3937 3.33357 19.5378 3.47485C19.6819 3.61614 19.7629 3.80789 19.7629 4.00768C19.7629 4.20747 19.6819 4.39925 19.5378 4.5405C19.3937 4.68176 19.1981 4.76117 18.9943 4.76117ZM18.9943 3.75649C18.8907 3.75649 18.7972 3.81772 18.7576 3.91157C18.718 4.00542 18.7398 4.1134 18.8131 4.18528C18.8864 4.25715 18.9966 4.27857 19.0923 4.23966C19.1881 4.20086 19.2505 4.10925 19.2505 4.00766C19.2505 3.86896 19.1358 3.75649 18.9943 3.75649Z"
                fill="#F8D15C"
            />
            <path
                d="M4.84901 4.76117C4.64519 4.76117 4.44958 4.68179 4.30549 4.5405C4.16139 4.39922 4.08039 4.20747 4.08039 4.00768C4.08039 3.80789 4.16137 3.61611 4.30549 3.47485C4.44961 3.3336 4.64521 3.25418 4.84901 3.25418C5.05281 3.25418 5.24844 3.33357 5.39254 3.47485C5.53663 3.61614 5.61764 3.80789 5.61764 4.00768C5.61764 4.20747 5.53666 4.39925 5.39254 4.5405C5.24842 4.68176 5.05281 4.76117 4.84901 4.76117ZM4.84901 3.75649C4.74538 3.75649 4.65193 3.81772 4.61236 3.91157C4.57267 4.00542 4.59452 4.1134 4.66783 4.18528C4.74115 4.25715 4.8513 4.27857 4.94704 4.23966C5.04277 4.20086 5.10523 4.10925 5.10523 4.00766C5.10523 3.86896 4.9905 3.75649 4.84901 3.75649Z"
                fill="#F8D15C"
            />
            <path
                d="M9.7924 9.46019C9.7924 9.66 9.71142 9.85176 9.5673 9.99301C9.42318 10.1343 9.22758 10.2137 9.02378 10.2137C8.81998 10.2137 8.62435 10.1343 8.48025 9.99301C8.33616 9.85173 8.25515 9.65998 8.25515 9.46019C8.25515 9.2604 8.33613 9.06862 8.48025 8.92736C8.62438 8.78611 8.81998 8.70669 9.02378 8.70669C9.22758 8.70669 9.42321 8.78608 9.5673 8.92736C9.7114 9.06865 9.7924 9.2604 9.7924 9.46019ZM9.02376 9.20902C8.92013 9.20902 8.82668 9.27024 8.78711 9.36409C8.74742 9.45794 8.76927 9.56592 8.84258 9.6378C8.9159 9.70968 9.02605 9.73109 9.12179 9.69218C9.21752 9.65339 9.27998 9.56178 9.27998 9.46019C9.27998 9.32148 9.16525 9.20902 9.02376 9.20902Z"
                fill="#F8D15C"
            />
            <path
                d="M7.99872 15.4879C8.20255 15.4879 8.39815 15.5673 8.54225 15.7086C8.68634 15.8498 8.76735 16.0416 8.76735 16.2414C8.76735 16.4412 8.68637 16.6329 8.54225 16.7742C8.39813 16.9155 8.20252 16.9949 7.99872 16.9949C7.79492 16.9949 7.59929 16.9155 7.4552 16.7742C7.3111 16.6329 7.2301 16.4412 7.2301 16.2414C7.2301 16.0416 7.31108 15.8498 7.4552 15.7086C7.59932 15.5673 7.79492 15.4879 7.99872 15.4879ZM7.99872 16.4926C8.10235 16.4926 8.1958 16.4313 8.23538 16.3375C8.27507 16.2436 8.25322 16.1357 8.1799 16.0638C8.10658 15.9919 7.99644 15.9705 7.9007 16.0094C7.80496 16.0482 7.74251 16.1398 7.74251 16.2414C7.74251 16.308 7.7695 16.3719 7.81754 16.419C7.86558 16.4661 7.93078 16.4926 7.99872 16.4926Z"
                fill="#F8D15C"
            />
            <path
                d="M15.6854 15.4879C15.8892 15.4879 16.0848 15.5673 16.2289 15.7086C16.373 15.8498 16.454 16.0416 16.454 16.2414C16.454 16.4412 16.373 16.6329 16.2289 16.7742C16.0848 16.9155 15.8892 16.9949 15.6854 16.9949C15.4816 16.9949 15.2859 16.9155 15.1418 16.7742C14.9977 16.6329 14.9167 16.4412 14.9167 16.2414C14.9167 16.0416 14.9977 15.8498 15.1418 15.7086C15.286 15.5673 15.4816 15.4879 15.6854 15.4879ZM15.6854 16.4926C15.789 16.4926 15.8824 16.4313 15.922 16.3375C15.9617 16.2436 15.9399 16.1357 15.8665 16.0638C15.7932 15.9919 15.6831 15.9705 15.5873 16.0094C15.4916 16.0482 15.4291 16.1398 15.4291 16.2414C15.4291 16.308 15.4561 16.3719 15.5042 16.419C15.5522 16.4661 15.6174 16.4926 15.6854 16.4926Z"
                fill="#F8D15C"
            />
            <path
                d="M13.3793 3.39371H12.8669C12.8691 2.52461 13.2862 1.70662 13.9942 1.18342L14.3017 1.58529C13.7225 2.01328 13.3811 2.6826 13.3793 3.39371Z"
                fill="#F8D15C"
            />
            <path
                d="M9.28882 1.54897L9.59627 1.1471C10.3043 1.67031 10.7214 2.48829 10.7236 3.35739H10.2112C10.2095 2.64628 9.86804 1.97696 9.28882 1.54897Z"
                fill="#F8D15C"
            />
            <path d="M14.5852 4.76117L14.0729 4.25895L13.7106 4.61418L14.2229 5.1164L14.5852 4.76117Z" fill="#F8D15C" />
            <path d="M9.97329 4.61418L9.61093 4.25895L9.09862 4.76117L9.46098 5.1164L9.97329 4.61418Z" fill="#F8D15C" />
            <path
                d="M16.2988 6.19386L16.0146 5.77586L15.2458 6.27832L15.5301 6.69632L16.2988 6.19386Z"
                fill="#F8D15C"
            />
            <path d="M8.46414 6.40075L7.69538 5.8983L7.41114 6.3163L8.1799 6.81876L8.46414 6.40075Z" fill="#F8D15C" />
            <path
                d="M6.38623 16.4191L6.02388 16.0639L5.25512 16.8175L5.61748 17.1728L6.38623 16.4191Z"
                fill="#F8D15C"
            />
            <path d="M18.4284 16.8172L17.6596 16.0636L17.2973 16.4188L18.066 17.1724L18.4284 16.8172Z" fill="#F8D15C" />
            <path
                d="M11.8419 8.95781C12.1137 8.95781 12.3744 9.06366 12.5666 9.25204C12.7588 9.44053 12.8668 9.69608 12.8668 9.96249C12.8667 10.3057 12.8486 10.6488 12.8127 10.9903L12.0969 17.7739C12.0837 17.9023 11.9735 17.9999 11.8419 17.9999C11.7104 17.9999 11.6001 17.9023 11.587 17.7739L10.8711 10.9894C10.8351 10.6482 10.8172 10.3054 10.8171 9.96243C10.8171 9.69602 10.9251 9.44046 11.1172 9.25198C11.3095 9.0636 11.5702 8.95781 11.8419 8.95781ZM11.3807 10.9386L11.8419 15.3081L12.3031 10.9377C12.3372 10.6137 12.3542 10.2882 12.3543 9.96241C12.3543 9.78289 12.2567 9.61705 12.0981 9.52735C11.9396 9.43765 11.7442 9.43765 11.5857 9.52735C11.4272 9.61705 11.3295 9.78289 11.3295 9.96241C11.3295 10.2884 11.3466 10.6143 11.3807 10.9386Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M6.87131 12.1225C6.65055 11.9775 6.50426 11.7464 6.46959 11.4883C6.43505 11.2301 6.51546 10.9697 6.69046 10.7738C6.86547 10.5778 7.11803 10.4652 7.38373 10.4648H7.46472C7.70983 10.4641 7.94499 10.5596 8.11784 10.7299C8.81304 11.4075 9.36435 12.2135 9.73977 13.1012C10.115 13.9891 10.3071 14.9411 10.3046 15.9021V17.7487C10.3046 17.8768 10.2062 17.9844 10.0763 17.9984C10.067 17.9993 10.0578 17.9998 10.0484 17.9998C9.92838 17.9997 9.82464 17.918 9.79856 17.8032L9.29747 15.5929C8.97824 14.1715 8.10834 12.9273 6.87131 12.1225ZM9.77167 15.3754C9.64791 13.7544 8.93258 12.2313 7.75675 11.0851C7.67989 11.0092 7.57512 10.9667 7.466 10.967H7.38375C7.20246 10.9669 7.04257 11.0833 6.99007 11.2534C6.93756 11.4235 7.00482 11.6073 7.15569 11.7057C8.46981 12.5605 9.40451 13.8717 9.77167 15.3754Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M16.2179 10.4648H16.2988C16.5644 10.4652 16.8171 10.5778 16.9921 10.7738C17.1671 10.9697 17.2475 11.2301 17.213 11.4883C17.1783 11.7464 17.0319 11.9775 16.8113 12.1225C15.5752 12.9271 14.706 14.1707 14.3867 15.5912L13.8856 17.8015C13.8604 17.9171 13.756 17.9998 13.6355 18C13.6261 18 13.6166 17.9996 13.6073 17.9985C13.4775 17.9844 13.3793 17.8769 13.3793 17.7488V15.9023C13.3766 14.9413 13.5685 13.9893 13.9435 13.1016C14.3187 12.2138 14.8698 11.4078 15.5648 10.7301C15.7376 10.5598 15.9727 10.4641 16.2179 10.4648ZM16.5268 11.7058C16.6778 11.6073 16.7451 11.4236 16.6926 11.2535C16.64 11.0834 16.4801 10.967 16.2989 10.9671H16.2179C16.1088 10.9667 16.004 11.0092 15.9271 11.0852C14.7513 12.2313 14.0359 13.7544 13.9122 15.3754C14.279 13.8721 15.2133 12.5609 16.5268 11.7058Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M10.7913 7.05437L9.89452 6.39584C9.81377 6.33653 9.77614 6.23628 9.79821 6.13985C9.82029 6.0433 9.89818 5.96851 9.99701 5.94878L11.1417 5.72396L11.6029 4.5937C11.6418 4.49816 11.7361 4.43548 11.841 4.43548C11.9458 4.43548 12.0402 4.49816 12.079 4.5937L12.5402 5.72396L13.6865 5.94878C13.7853 5.96852 13.8632 6.04331 13.8853 6.13985C13.9073 6.23628 13.8697 6.33653 13.789 6.39584L12.8922 7.05437L13.1177 8.15403C13.1392 8.26235 13.0861 8.37179 12.9868 8.4237C12.8875 8.47562 12.7652 8.4579 12.6854 8.38009L11.8418 7.5547L10.9981 8.38175C10.9183 8.45957 10.796 8.4774 10.6967 8.42537C10.5974 8.37345 10.5443 8.26413 10.5658 8.15581L10.7913 7.05437ZM11.2268 6.74748C11.3061 6.80567 11.344 6.90356 11.3242 6.99865L11.234 7.44122L11.6607 7.02299C11.7608 6.92499 11.9228 6.92499 12.0229 7.02299L12.4495 7.44122L12.3593 6.99865C12.3395 6.90356 12.3774 6.80567 12.4566 6.74748L13.0232 6.33103L12.3029 6.18985C12.2179 6.17326 12.1474 6.11574 12.1151 6.03691L11.8417 5.36403L11.5673 6.03691C11.5351 6.11574 11.4645 6.17326 11.3795 6.18985L10.6603 6.33103L11.2268 6.74748Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M20.0007 8.59149L20.2261 9.69205C20.2476 9.80036 20.1946 9.9098 20.0953 9.96172C19.996 10.0136 19.8737 9.99592 19.7939 9.9181L18.9502 9.09182L18.1066 9.91887C18.0267 9.99669 17.9045 10.0144 17.8052 9.96249C17.7059 9.91046 17.6528 9.80114 17.6743 9.69282L17.8998 8.59149L17.003 7.93262C16.9223 7.8733 16.8846 7.77306 16.9067 7.67663C16.9288 7.58008 17.0067 7.50529 17.1055 7.48556L18.2502 7.26074L18.7114 6.13048C18.7503 6.03494 18.8446 5.97226 18.9494 5.97226C19.0543 5.97226 19.1487 6.03494 19.1875 6.13048L19.6487 7.26074L20.795 7.48578C20.8938 7.50552 20.9717 7.58031 20.9938 7.67685C21.0158 7.77328 20.9782 7.87353 20.8975 7.93284L20.0007 8.59149ZM19.4114 7.72698C19.3264 7.71049 19.2558 7.65275 19.2236 7.57392L18.9502 6.90104L18.6758 7.57392C18.6436 7.65275 18.573 7.71038 18.488 7.72686L17.7688 7.86804L18.3353 8.28426C18.4146 8.34246 18.4525 8.44035 18.4327 8.53543L18.3425 8.97778L18.7691 8.55909C18.8691 8.46097 19.0313 8.46097 19.1314 8.55909L19.5579 8.977L19.4678 8.53543C19.448 8.44035 19.4859 8.34246 19.5651 8.28426L20.1317 7.86804L19.4114 7.72698Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M3.20494 7.41159L4.34967 7.18678L4.81086 6.05651C4.84975 5.96098 4.944 5.8983 5.04889 5.8983C5.15377 5.8983 5.24814 5.96098 5.28691 6.05651L5.7481 7.18678L6.89283 7.41159C6.99165 7.43133 7.06955 7.50612 7.09174 7.60266C7.11381 7.69909 7.07607 7.79934 6.99543 7.85866L6.09868 8.51718L6.3231 9.61785C6.3446 9.72617 6.29153 9.8356 6.19225 9.88752C6.09285 9.93944 5.97057 9.92172 5.89085 9.8439L5.04968 9.01763L4.20602 9.84468C4.12618 9.9225 4.00391 9.94021 3.90462 9.8883C3.80534 9.83627 3.75227 9.72694 3.77377 9.61863L3.99922 8.51729L3.10247 7.85842C3.02172 7.79911 2.98408 7.69886 3.00616 7.60243C3.02823 7.50589 3.10612 7.43133 3.20494 7.41159ZM4.43477 8.21006C4.51403 8.26825 4.55189 8.36614 4.53211 8.46123L4.44197 8.90357L4.86861 8.48566C4.96869 8.38765 5.13077 8.38754 5.23085 8.48554L5.65748 8.90346L5.56724 8.46123C5.54745 8.36614 5.58531 8.26825 5.66457 8.21006L6.23111 7.79361L5.5119 7.65243C5.42692 7.63584 5.35634 7.57832 5.32408 7.49949L5.04968 6.82681L4.77528 7.49969C4.74303 7.57852 4.67246 7.63615 4.58747 7.65263L3.86826 7.79381L4.43477 8.21006Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M16.319 4.94517H15.8066V4.81578C15.8047 4.1069 15.9462 3.40463 16.223 2.74967C16.4997 2.09461 16.9062 1.49999 17.4189 1L17.7813 1.35523C17.3162 1.80857 16.9476 2.34791 16.6966 2.94196C16.4456 3.5359 16.3173 4.17281 16.319 4.81575V4.94517Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M7.53895 4.84258C7.54066 4.19964 7.41244 3.56274 7.16136 2.9688C6.91041 2.37474 6.54177 1.8354 6.07669 1.38206L6.43905 1.02684C6.95182 1.52682 7.35834 2.12145 7.63502 2.7765C7.91182 3.43144 8.05331 4.13371 8.05138 4.84261V4.97201H7.53895V4.84258Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M15.9413 14.7349V14.2326H16.4356C17.6641 14.2293 18.8431 14.708 19.7095 15.562L19.3472 15.9172C18.5767 15.1578 17.5283 14.732 16.4356 14.7349L15.9413 14.7349Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M7.74245 14.2326V14.7349H7.24821C6.15554 14.732 5.10711 15.1577 4.33663 15.9172L3.97427 15.562C4.84071 14.708 6.01964 14.2293 7.24824 14.2326L7.74245 14.2326Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M7.17065 13.3178L6.77708 13.6393L6.48013 13.2901C5.90595 12.6127 5.05391 12.2215 4.15556 12.2227V11.7204C5.20591 11.719 6.20228 12.1765 6.8737 12.9685L7.17065 13.3178Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M19.5283 12.2227C18.63 12.2215 17.7779 12.6128 17.2037 13.2902L16.9067 13.6393L16.5131 13.3179L16.8101 12.9685C17.4815 12.1765 18.478 11.719 19.5283 11.7204L19.5283 12.2227Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M15.4291 9.46019C15.4291 9.66 15.3481 9.85176 15.204 9.99301C15.0599 10.1343 14.8643 10.2137 14.6605 10.2137C14.4567 10.2137 14.261 10.1343 14.1169 9.99301C13.9728 9.85173 13.8918 9.65998 13.8918 9.46019C13.8918 9.2604 13.9728 9.06862 14.1169 8.92736C14.2611 8.78611 14.4567 8.70669 14.6605 8.70669C14.8643 8.70669 15.0599 8.78608 15.204 8.92736C15.3481 9.06865 15.4291 9.2604 15.4291 9.46019ZM14.6604 9.20902C14.5568 9.20902 14.4634 9.27024 14.4238 9.36409C14.3841 9.45794 14.4059 9.56592 14.4793 9.6378C14.5526 9.70968 14.6627 9.73109 14.7585 9.69218C14.8542 9.65339 14.9167 9.56178 14.9167 9.46019C14.9167 9.32148 14.8019 9.20902 14.6604 9.20902Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M18.9943 4.76117C18.7905 4.76117 18.5949 4.68179 18.4508 4.5405C18.3067 4.39922 18.2257 4.20747 18.2257 4.00768C18.2257 3.80789 18.3067 3.61611 18.4508 3.47485C18.5949 3.3336 18.7905 3.25418 18.9943 3.25418C19.1981 3.25418 19.3937 3.33357 19.5378 3.47485C19.6819 3.61614 19.7629 3.80789 19.7629 4.00768C19.7629 4.20747 19.6819 4.39925 19.5378 4.5405C19.3937 4.68176 19.1981 4.76117 18.9943 4.76117ZM18.9943 3.75649C18.8907 3.75649 18.7972 3.81772 18.7576 3.91157C18.718 4.00542 18.7398 4.1134 18.8131 4.18528C18.8864 4.25715 18.9966 4.27857 19.0923 4.23966C19.1881 4.20086 19.2505 4.10925 19.2505 4.00766C19.2505 3.86896 19.1358 3.75649 18.9943 3.75649Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M4.84901 4.76117C4.64519 4.76117 4.44958 4.68179 4.30549 4.5405C4.16139 4.39922 4.08039 4.20747 4.08039 4.00768C4.08039 3.80789 4.16137 3.61611 4.30549 3.47485C4.44961 3.3336 4.64521 3.25418 4.84901 3.25418C5.05281 3.25418 5.24844 3.33357 5.39254 3.47485C5.53663 3.61614 5.61764 3.80789 5.61764 4.00768C5.61764 4.20747 5.53666 4.39925 5.39254 4.5405C5.24842 4.68176 5.05281 4.76117 4.84901 4.76117ZM4.84901 3.75649C4.74538 3.75649 4.65193 3.81772 4.61236 3.91157C4.57267 4.00542 4.59452 4.1134 4.66783 4.18528C4.74115 4.25715 4.8513 4.27857 4.94704 4.23966C5.04277 4.20086 5.10523 4.10925 5.10523 4.00766C5.10523 3.86896 4.9905 3.75649 4.84901 3.75649Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M9.7924 9.46019C9.7924 9.66 9.71142 9.85176 9.5673 9.99301C9.42318 10.1343 9.22758 10.2137 9.02378 10.2137C8.81998 10.2137 8.62435 10.1343 8.48025 9.99301C8.33616 9.85173 8.25515 9.65998 8.25515 9.46019C8.25515 9.2604 8.33613 9.06862 8.48025 8.92736C8.62438 8.78611 8.81998 8.70669 9.02378 8.70669C9.22758 8.70669 9.42321 8.78608 9.5673 8.92736C9.7114 9.06865 9.7924 9.2604 9.7924 9.46019ZM9.02376 9.20902C8.92013 9.20902 8.82668 9.27024 8.78711 9.36409C8.74742 9.45794 8.76927 9.56592 8.84258 9.6378C8.9159 9.70968 9.02605 9.73109 9.12179 9.69218C9.21752 9.65339 9.27998 9.56178 9.27998 9.46019C9.27998 9.32148 9.16525 9.20902 9.02376 9.20902Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M7.99872 15.4879C8.20255 15.4879 8.39815 15.5673 8.54225 15.7086C8.68634 15.8498 8.76735 16.0416 8.76735 16.2414C8.76735 16.4412 8.68637 16.6329 8.54225 16.7742C8.39813 16.9155 8.20252 16.9949 7.99872 16.9949C7.79492 16.9949 7.59929 16.9155 7.4552 16.7742C7.3111 16.6329 7.2301 16.4412 7.2301 16.2414C7.2301 16.0416 7.31108 15.8498 7.4552 15.7086C7.59932 15.5673 7.79492 15.4879 7.99872 15.4879ZM7.99872 16.4926C8.10235 16.4926 8.1958 16.4313 8.23538 16.3375C8.27507 16.2436 8.25322 16.1357 8.1799 16.0638C8.10658 15.9919 7.99644 15.9705 7.9007 16.0094C7.80496 16.0482 7.74251 16.1398 7.74251 16.2414C7.74251 16.308 7.7695 16.3719 7.81754 16.419C7.86558 16.4661 7.93078 16.4926 7.99872 16.4926Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M15.6854 15.4879C15.8892 15.4879 16.0848 15.5673 16.2289 15.7086C16.373 15.8498 16.454 16.0416 16.454 16.2414C16.454 16.4412 16.373 16.6329 16.2289 16.7742C16.0848 16.9155 15.8892 16.9949 15.6854 16.9949C15.4816 16.9949 15.2859 16.9155 15.1418 16.7742C14.9977 16.6329 14.9167 16.4412 14.9167 16.2414C14.9167 16.0416 14.9977 15.8498 15.1418 15.7086C15.286 15.5673 15.4816 15.4879 15.6854 15.4879ZM15.6854 16.4926C15.789 16.4926 15.8824 16.4313 15.922 16.3375C15.9617 16.2436 15.9399 16.1357 15.8665 16.0638C15.7932 15.9919 15.6831 15.9705 15.5873 16.0094C15.4916 16.0482 15.4291 16.1398 15.4291 16.2414C15.4291 16.308 15.4561 16.3719 15.5042 16.419C15.5522 16.4661 15.6174 16.4926 15.6854 16.4926Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M13.3793 3.39371H12.8669C12.8691 2.52461 13.2862 1.70662 13.9942 1.18342L14.3017 1.58529C13.7225 2.01328 13.3811 2.6826 13.3793 3.39371Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M9.28882 1.54897L9.59627 1.1471C10.3043 1.67031 10.7214 2.48829 10.7236 3.35739H10.2112C10.2095 2.64628 9.86804 1.97696 9.28882 1.54897Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M14.5852 4.76117L14.0729 4.25895L13.7106 4.61418L14.2229 5.1164L14.5852 4.76117Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M9.97329 4.61418L9.61093 4.25895L9.09862 4.76117L9.46098 5.1164L9.97329 4.61418Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M16.2988 6.19386L16.0146 5.77586L15.2458 6.27832L15.5301 6.69632L16.2988 6.19386Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M8.46414 6.40075L7.69538 5.8983L7.41114 6.3163L8.1799 6.81876L8.46414 6.40075Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M6.38623 16.4191L6.02388 16.0639L5.25512 16.8175L5.61748 17.1728L6.38623 16.4191Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M18.4284 16.8172L17.6596 16.0636L17.2973 16.4188L18.066 17.1724L18.4284 16.8172Z"
                stroke="#F8D15C"
                strokeWidth="0.3"
                mask="url(#path-1-outside-1_35_2283)"
            />
            <path
                d="M3 23.5V19.5C3 18.3954 3.89543 17.5 5 17.5H19C20.1046 17.5 21 18.3954 21 19.5V23.5M3 23.5V31C3 31.2761 3.22386 31.5 3.5 31.5H20.5C20.7761 31.5 21 31.2761 21 31V23.5M3 23.5H10M10 23.5V22.5C10 21.9477 10.4477 21.5 11 21.5H13C13.5523 21.5 14 21.9477 14 22.5V23.5M10 23.5V24.5C10 25.0523 10.4477 25.5 11 25.5H13C13.5523 25.5 14 25.0523 14 24.5V23.5M14 23.5H21"
                stroke="#F8D15C"
                strokeWidth="2"
            />
        </svg>
    );

    return isFullCollection ? fullCollectionStyle : defaultStyle;
});
