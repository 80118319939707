import React, { useEffect, useState, useCallback, useRef } from 'react';

import { Player } from '@lottiefiles/react-lottie-player';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSelector } from 'react-redux';

import styles from './HighFive.css';
import { getRandomIntFromInterval } from '../../../helpers/getRandomIntFromInterval';
import * as highFiveAnimation from '../../../public/animations/highfive_slap.json';
import { Avatar, AvatarTypes } from '../../atoms/Avatar/Avatar';
import { AvatarImagesDefault } from '../../constants/AvatarImagesData';
import HighFiveGames from '../../constants/HighFiveGames';
import { getUserFakeName } from '../../constants/UserNameFakeParts';
import { IGame } from '../../models/Game/Game';
import { Analytics } from '../../services/Analytics/Analytics';
import { UrlService } from '../../services/UrlService';
import { Button } from '../Button/Button';

dayjs.extend(utc);
export type HighFiveProps = {
    game: IGame;
};

export enum HighFiveState {
    DEFAULT = 'default',
    RECEIVED = 'received',
    GETTING = 'getting',
}

export const HighFive = React.memo(({ game }: HighFiveProps) => {
    const { shortTitle: gameName, alias: gameSlug } = game;
    const userNameFromRedux = useSelector((state) => state.user?.name) || '';
    const [blockState, setBlockState] = useState(HighFiveState.DEFAULT);
    const [arrayOfPlayers, setArrayOfPlayers] = useState([]);
    const [randomPlayerImage, setRandomPlayerImage] = useState('');
    const [randomPlayerName, setRandomPlayerName] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [animated, setAnimated] = useState(false);
    const [emulateHighFiveTimeout, setEmulateHighFiveTimeout] = useState(null);
    const [backToDefaultStateTimeout, setBackToDefaultStateTimeout] = useState(null);
    const ONE_MINUTE_DELAY = 60000; //60000
    const BASE_DELAY_BETWEEN_STATES = 3000;
    const MAX_USERS_ON_ARKADIUM = 7100;
    const MIN_USERS_ON_ARKADIUM = 2500;
    const DEFAULT_PLACE = 'on Arkadium';
    const AVATARS_BACKGROUND = `rgba(62,195,165, 1)`;
    // animPlayer element ref
    const highFiveAnimPlayer = useRef(null);
    // Ref for block state
    const blockStateRef = useRef(blockState);

    blockStateRef.current = blockState;

    const receivingTimeRef = useRef(null);
    let qsReceiveTime = null;
    let qsReceiveValue = null;
    let receivingTimeValue = null;

    /* Initialization*/
    useEffect(() => {
        const shuffledArray = AvatarImagesDefault.sort(() => 0.5 - Math.random());
        const userAvatarsBaseArray = shuffledArray.slice(0, 4);

        setArrayOfPlayers([...userAvatarsBaseArray]);

        /* Set random user avatar for Getting state */
        setRandomPlayerAvatar();

        const delay = getRandomIntFromInterval(3, 5);

        /* FOR TEST ONLY*/
        if (BUILD_ENV !== 'prod') {
            qsReceiveTime = UrlService.getQSParam(window.location.search, 'rDuration');

            if (qsReceiveTime) {
                qsReceiveValue = +qsReceiveTime;
            }
        }

        /* FOR TEST ONLY */
        receivingTimeValue = qsReceiveValue && qsReceiveValue >= 10000 ? qsReceiveValue : delay * ONE_MINUTE_DELAY;
        receivingTimeRef.current = receivingTimeValue;

        return () => {
            clearTimeout(emulateHighFiveTimeout);
            clearTimeout(backToDefaultStateTimeout);
        };
    }, []);

    /* Set default state after GETTING HighFive */
    useEffect(() => {
        if (blockState === HighFiveState.GETTING) {
            setBtnDisabled(false);

            if (!animated) {
                playAnimation();
            }

            setBackToDefaultStateTimeout(
                setTimeout(() => {
                    if (blockStateRef.current !== HighFiveState.DEFAULT) {
                        setBlockState(HighFiveState.DEFAULT);
                        Analytics.trackEvent(Analytics.games.sendEmoteExpire(game));
                    }
                }, ONE_MINUTE_DELAY)
            ); //ONE_MINUTE_DELAY
        }

        if (blockState === HighFiveState.DEFAULT) {
            emulateHighFive();
        }
    }, [blockState]);

    /**
     *Main method for HighFive emulation (get HighFive from another user)
     *
     */
    const emulateHighFive = () => {
        setEmulateHighFiveTimeout(
            setTimeout(() => {
                clearTimeout(backToDefaultStateTimeout);

                if (blockStateRef.current == HighFiveState.DEFAULT) {
                    getUserRandomName(() => {
                        setBlockState(HighFiveState.GETTING);
                    });
                }
            }, receivingTimeRef.current)
        );
    };
    /**
     * Random avatar image setter
     */
    const setRandomPlayerAvatar = () => {
        const randomIndex = Math.floor(Math.random() * (AvatarImagesDefault.length - 1));

        setRandomPlayerImage(AvatarImagesDefault[randomIndex].filename);
    };
    /**
     * Getter for user name with async request from UserAPI
     *
     * @param {any} callback
     */
    const getUserRandomName = (callback) => {
        let userName = getUserFakeName();

        if (userNameFromRedux == userName) {
            userName = userName + '0'; // Hack for same names
        }

        setRandomPlayerName(userName);
        callback();
        setAnimated(false);
    };
    /**
     * Getter for counter of people playing
     *
     * @param {number} minUsers
     * @param {number*} maxUsers
     * @return {number}
     */
    const getCounter = (minUsers, maxUsers) => {
        const currentHour = dayjs().utc().hour() + 1;
        const usersPerHour = Math.floor((maxUsers - minUsers) / 24);
        const usersOffset = usersPerHour * getRandomIntFromInterval(1, 3);
        const resultValue = minUsers + usersPerHour * currentHour + usersOffset;

        if (resultValue > maxUsers) {
            return maxUsers - usersOffset;
        }

        return resultValue;
    };
    /* Getter for info text */
    const getInfoText = useCallback(() => {
        if (blockState === HighFiveState.DEFAULT) {
            let place = DEFAULT_PLACE;
            let counter = null;
            const isTestGame = HighFiveGames.find((item) => item.slug === gameSlug);

            if (isTestGame) {
                const { maxUsers, minUsers } = isTestGame;

                place = gameName;
                counter = getCounter(minUsers, maxUsers);
            } else {
                const maxUsers = MAX_USERS_ON_ARKADIUM;
                const minUsers = MIN_USERS_ON_ARKADIUM;

                counter = getCounter(minUsers, maxUsers);
            }

            return (
                <>
                    <strong>{counter} people</strong> are playing <br />
                    {place}
                </>
            );
        }

        if (blockState === HighFiveState.RECEIVED) {
            return (
                <>
                    {randomPlayerName} <br />
                    <strong> received your high five</strong>
                </>
            );
        }

        if (blockState === HighFiveState.GETTING) {
            return (
                <>
                    {randomPlayerName} <br />
                    <strong> gave you a high five</strong>
                </>
            );
        }
    }, [blockState]);
    /**
     * Getter for HighFive btn text
     *
     * @return {*}
     */
    const getHighFiveText = () => {
        switch (blockState) {
            case HighFiveState.DEFAULT:
                return 'Give someone a high five';
            case HighFiveState.RECEIVED:
                return `You gave a high five`;
            case HighFiveState.GETTING:
                return `Give a high five back`;
            default:
                break;
        }

        return '';
    };
    /**
     * Setter for status and disable btn
     *
     * @param {*} delay
     */
    const setStatusBackToDefaultState = (delay) => {
        setTimeout(() => {
            setBlockState(HighFiveState.DEFAULT);
            setBtnDisabled(true);
        }, delay);
        disableHighFiveBtn(ONE_MINUTE_DELAY);
    };
    /**
     * Disable main btn handler
     *
     * @param {number} delay
     */
    const disableHighFiveBtn = (delay) => {
        setTimeout(() => {
            setBtnDisabled(false);
        }, delay);
    };
    /**
     * Play animation High Five
     *
     */
    const playAnimation = () => {
        if (highFiveAnimPlayer?.current) highFiveAnimPlayer.current.play();
    };
    /**
     * Main Handler - send High Five
     * 1 Change Avatar
     * 2 Change Block state
     * 2 Get random UserName
     * 3 Disable btn after changes for 1 minute
     */
    const sendHighFive = () => {
        if (blockState !== HighFiveState.RECEIVED) {
            playAnimation();
        }

        if (blockState === HighFiveState.DEFAULT) {
            clearTimeout(emulateHighFiveTimeout);
            clearTimeout(backToDefaultStateTimeout);
            setRandomPlayerAvatar();
            getUserRandomName(() => {
                setBlockState(HighFiveState.RECEIVED);
                Analytics.trackEvent(Analytics.games.sendEmote(game));
                setStatusBackToDefaultState(BASE_DELAY_BETWEEN_STATES);
            });
        }

        if (blockState === HighFiveState.RECEIVED) {
            Analytics.trackEvent(Analytics.games.sendEmote(game));
        }

        if (blockState === HighFiveState.GETTING) {
            clearTimeout(emulateHighFiveTimeout);
            clearTimeout(backToDefaultStateTimeout);
            setBlockState(HighFiveState.RECEIVED);
            Analytics.trackEvent(Analytics.games.returnEmote(game));
            setStatusBackToDefaultState(BASE_DELAY_BETWEEN_STATES);
        }
    };
    /**
     * Render Avatar func for DRY
     *
     * @param {string} value
     * @return {ReactNode}
     */
    const renderAvatar = (value) => {
        return (
            <Avatar
                key={value}
                image={value}
                selected={false}
                selectable={false}
                onSelect={() => {}}
                avatarList
                background={AVATARS_BACKGROUND}
                size={AvatarTypes.SMALL}
                noFrame
                noHover
            />
        );
    };

    return (
        <>
            <Box className={styles.wrapper}>
                <Box className={styles.info}>
                    <Box className={styles.info__avatars}>
                        {blockState === HighFiveState.DEFAULT
                            ? arrayOfPlayers.map(({ filename }) => renderAvatar(filename))
                            : renderAvatar(randomPlayerImage)}
                    </Box>
                    <Box className={styles.info__text}>{getInfoText()}</Box>
                </Box>
                <Button
                    className={classNames(styles.counterBtn, {
                        [styles.counterBtnReceived]: blockState === HighFiveState.RECEIVED,
                    })}
                    disabled={btnDisabled}
                    onClick={sendHighFive}
                >
                    <Box className={classNames(styles.counterBtn_image)}>
                        <Player ref={highFiveAnimPlayer} src={highFiveAnimation} />
                    </Box>
                    <Box className={styles.counterBtn_text}>{getHighFiveText()}</Box>
                </Button>
            </Box>
        </>
    );
});
