import React, { FC, Suspense, RefObject } from 'react';

import { useTranslation } from 'react-i18next';

import BottomPromoBlock from './BottomPromoBlock/BottomPromoBlock';
import styles from './GameFooter.css';
import { MiscUtils } from '../../../../utils/MiscUtils';
import { AppLoader } from '../../../atoms/AppLoader/AppLoader';
import { IGame } from '../../../models/Game/Game';
import { GameCardTypes } from '../../../molecules/GameCard/GameCard';
import { OpenWebBlock } from '../../../molecules/OpenWeb/OpenWebConversations';
import { PageDescription } from '../../../molecules/PageDescription/PageDescription';
import { Media } from '../../../services/MediaService';
import { TabNames } from '../GameTemplate';

interface IGameFooter {
    game: IGame;
    recommendedGames: IGame[];
    recommendedGamesRef: RefObject<HTMLDivElement>;
    adDividerRef: RefObject<HTMLDivElement>;
    pageDescriptionRef: RefObject<HTMLDivElement>;
    isVisibleMobileSlider: boolean;
    isAdFree: boolean;
}

const HomepageSlider = React.lazy(() =>
    MiscUtils.loadableRetry(() => import('../../../molecules/HomepageSlider/HomepageSlider'), { retries: 3 })
);
const GameFooter: FC<IGameFooter> = (props) => {
    const {
        game,
        recommendedGames,
        recommendedGamesRef,
        adDividerRef,
        pageDescriptionRef,
        isVisibleMobileSlider,
        isAdFree,
    } = props;
    const { t } = useTranslation();
    const getPageDescriptionContent = (game: IGame) => {
        const tabs = [
            {
                key: 'comments',
                tabName: TabNames.COMMENTS,
                description: <OpenWebBlock topicId={game.alias} game={game} linkPath={`games/${game.alias}`} />,
            },
            {
                key: 'rules',
                tabName: TabNames.HOW_TO_PLAY,
                description: game.rules,
            },
            {
                key: 'description',
                tabName: TabNames.ABOUT,
                description: game.description,
            },
        ];

        return tabs.filter((tab) => tab.description);
    };

    return (
        <div className={styles.gameAreaFooterContent}>
            <Media lessThan="ARK_SMALL_DESKTOP">
                <div className={styles.gameAreaFooter}>
                    <h1 className={styles.gameAreaGameTitle}>{game.shortTitle}</h1>
                    <div className={styles.gameAreaGameTextContainer}>
                        <PageDescription content={getPageDescriptionContent(game)} className="gamePageTab" />
                    </div>
                    <div ref={recommendedGamesRef} className={styles.gameAreaRecommendedGamesContainer}>
                        {isVisibleMobileSlider && !MiscUtils.isServer && (
                            <Suspense
                                fallback={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <AppLoader />
                                    </div>
                                }
                            >
                                <HomepageSlider
                                    items={recommendedGames}
                                    gameCardType={GameCardTypes.SMALL}
                                    promoboxCategory="Game Page"
                                    caption={t('RECOMMENDED_TO_YOU')}
                                    captionTag="p"
                                    game={game}
                                />
                            </Suspense>
                        )}
                    </div>
                </div>
            </Media>
            <Media greaterThan="ARK_SMALL_DESKTOP_BELOW">
                <div className={styles.gameAreaFooter}>
                    <div className={styles.gameAreaRecommendedGamesContainer}>
                        {!MiscUtils.isServer && (
                            <Suspense
                                fallback={
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <AppLoader />
                                    </div>
                                }
                            >
                                <HomepageSlider
                                    items={recommendedGames}
                                    gameCardType={GameCardTypes.MEDIUM}
                                    promoboxCategory="Game Page"
                                    caption={t('RECOMMENDED_TO_YOU')}
                                    captionTag="p"
                                    game={game}
                                />
                            </Suspense>
                        )}
                    </div>
                    {!isAdFree && <BottomPromoBlock ref={adDividerRef} game={game} t={t} />}
                    <div className={styles.gameAreaGameTextContainer} ref={pageDescriptionRef}>
                        <PageDescription content={getPageDescriptionContent(game)} className="gamePageTab" />
                    </div>
                </div>
            </Media>
        </div>
    );
};

export default GameFooter;
