import { IGame } from './Game/Game';

export type GamesListFn = (gamesList: IGame[]) => IGame[];

export type CategorySection = {
    type: string;
    name: string;
    gameSlugs: string[];
    gamesListFn: GamesListFn;
    key?: string;
};

export type SideMenuSuggestionsGames = {
    name: string;
    games: Array<string>;
};

export type GameLists = {
    homepagePromoGames: string[];
    homepageCategoriesList: CategorySection[];
    gamesSearchTabPopularGames: string[];
    sideMenuSuggestionsGames: SideMenuSuggestionsGames[];
    gamesNoPrerollLoginIds: string[];
};

export type GameListsRedesign = {
    homepageCategoriesList: CategorySection[];
    gamesSearchTabPopularGames: string[];
    sideMenuSuggestionsGames: SideMenuSuggestionsGames[];
};

export const gamesSlugsFullScreen = [
    // 'bubble-shooter',
    'block-champ',
    // 'mahjong',
    'candy-mahjongg',
    'mahjongg-dimensions',
    'mahjongg-solitaire',
    'family-feud',
    'daily-crossword',
    // 'outspell-maxified',
];
