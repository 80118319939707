import React from 'react';

import {
  CollectionItemDto,
  UserCollectionItemDto
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/collection';
import classNames from 'classnames';

import styles from './CollectionItemsList.css';
import { CollectionsIcon } from '../../atoms/Icons/Styleguide/CollectionsIcon';
import { BackgroundTypes, CollectionItemCard } from '../../molecules/CollectionItemCard/CollectionItemCard';
import { MergedCollectionType } from '../../services/CollectionsService';

type CollectionItemCardProps = {
  collection: MergedCollectionType;
  backgroundType: BackgroundTypes;
  isTab?: boolean;
};

export const CollectionItemsList = ({ collection, backgroundType, isTab }: CollectionItemCardProps) => {
  const collectionItemsList = [...collection.items]
    // Sort in rareness order: RARE => VERY RARE => ULTRA RARE
    .sort((a, b) => a.type - b.type)
    .map(({ name, image, type, amount }: CollectionItemDto & UserCollectionItemDto) => (
      <CollectionItemCard
        key={name}
        isDiscovered={amount > 0}
        itemCountText={genItemCountText(amount)}
        cardName={name}
        imageSrc={image}
        rarenessIndex={type}
        backgroundType={backgroundType}
      />
    ));
  const quantityOfOpenedItems = collection.items.filter(
    (item: CollectionItemDto & UserCollectionItemDto) => item.amount > 0
  ).length;
  const isFullCollection = quantityOfOpenedItems === collection.items.length;

  return (
    <div className={classNames(styles.container, { [styles.tab]: isTab })}>
      <div
        className={classNames(styles.collectionNameWrapper, {
          [styles.fullCollection]: isFullCollection
        })}
      >
        <div
          className={classNames(styles.titleWrapper, {
            [styles.undiscovered]: quantityOfOpenedItems <= 0
          })}
        >
          <div className={styles.counterWrapper}>
            <CollectionsIcon isFullCollection={isFullCollection}/>
            <span>{`${quantityOfOpenedItems}/${collection.items.length}`}</span>
          </div>
          <p className={styles.collectionName}>{`${collection.name} Collection`}</p>
        </div>
      </div>
      <div className={styles.listWrapper}>{collectionItemsList}</div>
    </div>
  );
};

export function genItemCountText(amount: number): string {
  if (amount >= 10) {
    return '9+';
  }

  if (amount > 1) {
    return `${amount}`;
  }

  return '';
}
