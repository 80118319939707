import React from 'react';

import { TFunction } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';

import styles from './GamePageAdNewLayoutTest.css';
import { DesktopQuery, Responsive } from '../../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { HighFive } from '../../../../FigmaStyleguide/HighFive/HighFive';
import { IGame } from '../../../../models/Game/Game';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../../molecules/Ad/Ad';
import { Analytics } from '../../../../services/Analytics/Analytics';
import UserService from '../../../../services/UserService';
import { GamePageDisplayAdProps } from '../GamePageDisplayAdProps';

type GameAreaRightAdProps = {
    dispatch?: Dispatch<Action>;
    game: IGame;
    t: TFunction;
    keyValues: [string[]];
} & GamePageDisplayAdProps;
class GameAreaRightAdNewLayoutTestBase extends React.PureComponent<GameAreaRightAdProps> {
    trackedFeedbackImpression = false;

    feedbackRef = React.createRef<any>();

    isSubscriber = UserService.isUserSubscriber();

    trackIfVisible = () => {
        if (this.trackedFeedbackImpression) {
            return;
        }

        const el = this.feedbackRef.current;
        const html = document.body.parentElement;

        if (el) {
            const elR = el.getBoundingClientRect();

            if (elR.y + elR.height * 0.5 <= Math.abs(html.clientHeight)) {
                this.trackedFeedbackImpression = true;
                Analytics.trackEvent(Analytics.games.gamePageFeedbackImpression(this.props.game));
            }
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.trackIfVisible);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.trackIfVisible);
    }

    render() {
        const { className, adBackgroundTone, keyValues } = this.props;
        const topAdTypes = [AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250];
        const bottomAdTypes = [
            AdTypes.AD_336x280,
            AdTypes.AD_300x600,
            AdTypes.AD_300x250,
            AdTypes.AD_250x250,
            AdTypes.AD_160x600,
        ];

        return (
            <DesktopQuery>
                <div className={className}>
                    <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                        <Ad
                            hasBackground
                            adType={topAdTypes}
                            backgroundTone={adBackgroundTone}
                            keepSize={false}
                            captionPosition={CaptionPositionTypes.CENTER}
                            id="ark_display_r1"
                            useDefaultBidTimeout
                            keyValues={keyValues}
                            className={styles.rightAd320}
                        />
                        <div ref={this.feedbackRef}>
                            {!this.isSubscriber && <HighFive game={this.props.game} />}
                        </div>
                        <Ad
                            hasBackground
                            adType={bottomAdTypes}
                            backgroundTone={adBackgroundTone}
                            keepSize={false}
                            captionPosition={CaptionPositionTypes.CENTER}
                            id="ark_display_r2"
                            useDefaultBidTimeout
                            keyValues={keyValues}
                            className={styles.rightAd320}
                        />
                    </Responsive>
                </div>
            </DesktopQuery>
        );
    }
}
export const GameAreaRightAdNewLayoutTest = connect()(GameAreaRightAdNewLayoutTestBase);
