import React, { RefObject } from 'react';

import classNames from 'classnames';
import { batch, connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { checkFPS } from './CheckFPS';
import styles from './Game.css';
import { GameCanvas } from './GameCanvas';
import { devConsole } from '../../../utils/DevConsole';
import { HttpUtils } from '../../../utils/HttpUtils';
import { globalErrorHandler, globalLogHandler } from '../../../utils/LogUtils';
import { MiscUtils } from '../../../utils/MiscUtils';
import { GameLoader } from '../../atoms/Loader/Loader';
import { environment } from '../../config/environment';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { ARK_MESSAGES, GameState, IframeAuthApiCallbackActions, IframeMessageTypes } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { GameUserEventsNames, GameUserEventsResults } from '../../models/Game/GameEventsUserEvents';
import {
  GameEvents,
  GameObservable,
  PurchaseRequestEvent,
  PurchaseResponseEvent,
  SS_HURDLE_BOOST_PROPS
} from '../../models/Game/GameObservable';
import { UserModel } from '../../models/User/UserModel';
import AbTestService from '../../services/AbTestService';
import adsService from '../../services/AdsService';
import { Analytics, trackGameEvent } from '../../services/Analytics/Analytics';
import { FingerprintService } from '../../services/Analytics/FingerprintJS/FingerprintService';
import { LeanplumAnalytics } from '../../services/Analytics/LeanplumAnalytics';
import { handleLeanplumMarketingActions } from '../../services/Analytics/LeanplumAnalyticsMarketingActions';
import { CookieService } from '../../services/CookieService';
import GemsService from '../../services/GemsService';
import { LocalStorageService } from '../../services/LocalStorage';
import { LocalStorageListenedProps, lsSetGamePurchaseRequest } from '../../services/LocalStorageListenerLogic';
import PaymentService from '../../services/PaymentService';
import RecentlyPlayedService from '../../services/RecentlyPlayedService';
import UserService from '../../services/UserService';
import { AppState } from '../../store';
import { setGameObservable } from '../../store/ducks/games';
import { setSideMenuActivePage, setSideMenuOpened } from '../../store/ducks/layout';
import { GemsAnalyticsShopLocations, setGemsShopLocation } from '../../store/ducks/leanplum/lpAnalytics';
import { GemsEffects } from '../../store/effects/gems.effects';


type StoreProps = {
  dispatch: ThunkDispatch<AppState, void, Action>;
};

export type ComponentOwnProps = {
  game: IGame;
  gameState: GameState;
  isIframeGame: boolean;
  iframeRef: RefObject<HTMLIFrameElement>;
  iframeSourceCode: string | null;
  postMessageToIframe: (data: { type: IframeMessageTypes; payload: object }) => void;
  currentLang: string;
  keepAliveStatus: boolean;
  adFree: boolean;
  onGameEnd: (event: GameEvents) => void;
  onScoreChange: (event: GameEvents) => void;
  onRewardStart: (eventPayload: { callback: (o: unknown) => void }) => void;
  onInterstitialStart: (eventPayload: { callback: (o: unknown) => void }) => void;
  onGameTestReady: () => void;
  shopLocation?: GemsAnalyticsShopLocations; // ? because from store connection
  user: UserModel;
};

type GameUnitProps =
  StoreProps
  & ComponentOwnProps;

type GameUnitState = {
  isGameLoading: boolean;
  isGameDesktopFlex: boolean;
  aspectRatio: number;
  isIframeLoaded: boolean;
  requestEventsQueue: unknown[];
};

class GameUnitBase extends React.PureComponent<GameUnitProps, GameUnitState> {
  readonly state = {
    isGameLoading: true,
    isGameDesktopFlex: typeof this.props.game.isFlex === 'undefined' ? true : this.props.game.isFlex,
    aspectRatio: this.props.game.aspectRatio,
    isIframeLoaded: false,
    requestEventsQueue: [] as unknown[]
  };

  private gameSubject: GameObservable = new GameObservable();
  private unitContainerRef = React.createRef<HTMLDivElement>();
  private canvasContainerRef = React.createRef<HTMLDivElement>();
  private doGameEndTrigger = true;
  private doGameScoresTrigger = true;
  private marketingActionHandlerFunc = null;

  // This feature used by QA stuff to skip preroll
  onMessage = (event) => {
    try {
      if (event.data && event.data.actionName === ARK_MESSAGES.SET_GAME_TYPE) {
        this.setState({
          isGameDesktopFlex: event.data.isGameDesktopFlex,
          aspectRatio: event.data.aspectRatio ? event.data.aspectRatio : this.props.game.aspectRatio
        });
        this.onResize();
      }

      this.props.game?.alias === 'sudoku' && this.onResize(); // issue when iframe on => messages will be sent
    } catch (ex) {
      globalErrorHandler({ error: ex, filename: 'GameUnit.tsx', info: 'onMessage()' });
    }
  };

  saveRecentlyPlayed = (slug: string) => {
    const recentlyPlayedLocal = RecentlyPlayedService.recentlyPlayedAddLocal(slug);

    if (UserService.isUserLoggedIn()) {
      RecentlyPlayedService.recentlyPlayedSave(recentlyPlayedLocal, UserService.getToken);
    }
  };

  onGameEvent = async (event) => {
    const {
      onScoreChange,
      onGameEnd,
      onRewardStart,
      onInterstitialStart,
      isIframeGame,
      iframeSourceCode,
      onGameTestReady,
      dispatch
    } = this.props;

    if (isIframeGame && iframeSourceCode?.startsWith(event.origin)) {
      event = event.data;
    }

    let doLogic;

    switch (event.type as GameEvents & IframeMessageTypes) {
      case GameEvents.TEST_READY: {
        doLogic = async () => {
          this.setState({ isGameLoading: false });
          onGameTestReady();
        };

        break;
      }

      case GameEvents.UPDATE_LOCAL_STORAGE:
        break;
      case GameEvents.GAME_START:
        break;
      case GameEvents.EVENT_CHANGE:
        doLogic = async () => {
          adsService.refresh();
        };

        break;
      case GameEvents.CHANGE_SCORE:
        doLogic = async () => {
          const forceUpdate = event.forceUpdate as boolean;

          if (!forceUpdate && this.doGameScoresTrigger) {
            this.doGameScoresTrigger = false;

            if (onScoreChange) {
              onScoreChange(event);
            }
          } else if (forceUpdate && onScoreChange) {
            onScoreChange(event);
          }
        };

        break;
      case GameEvents.GAME_END:
        doLogic = async () => {
          if (this.doGameEndTrigger) {
            adsService.refresh();
            this.doGameEndTrigger = false;

            if (onGameEnd) {
              onGameEnd(event);
            }
          }
        };

        break;

      case GameEvents.REWARD_START: {
        doLogic = async () => {
          onRewardStart(event.payload);
        };

        break;
      }

      case GameEvents.INTERSTITIAL_START: {
        doLogic = async () => {
          onInterstitialStart(event.payload);
        };

        break;
      }

      case IframeMessageTypes.SET_LS_ITEM: {
        doLogic = async () => {
          const { key, value } = event.payload;

          LocalStorageService.setItem(key, value, true);
        };

        break;
      }

      case IframeMessageTypes.REMOVE_LS_ITEM: {
        doLogic = async () => {
          LocalStorageService.removeItem(event.payload.key);
        };

        break;
      }

      case GameEvents.AD_REFRESH:
        doLogic = async () => {
          devConsole('GameEvents.AD_REFRESH');
          adsService.refresh();
        };

        break;
      case GameEvents.PURCHASE_UPDATE:
        doLogic = async () => {
          dispatch(GemsEffects.UpdateGemsAmount());
        };

        break;
      case GameEvents.PURCHASE_REQUEST:
        doLogic = async () => {
          this.handlePurchaseRequestEvent.bind(this)(event);
        };

        break;
      case GameEvents.AUTH_API_EVENT:
        doLogic = async () => {
          const { auth_method, auth_method_args } = event?.payload || {};
          const authApi = UserService.authApiService;

          if (auth_method_args) {
            authApi?.[auth_method]?.(...auth_method_args);
          } else {
            authApi?.[auth_method]?.();
          }

          switch (auth_method) {
            case 'openWidget':
              return (
                !this.props.user &&
                batch(() => {
                  this.props.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
                  this.props.dispatch(setSideMenuOpened(true));
                })
              );
              break;
            case 'subscribeToUserAuthStatus':
              if (this.props?.user) {
                this.props.postMessageToIframe({
                  type: IframeMessageTypes.AUTH_API_EVENT,
                  payload: {
                    auth_method,
                    payload: {
                      ...this.props.user,
                      subscriptionId: UserService.getSubscriptionId(),
                      subscriptionPlan: UserService.getSubscriptionType(),
                      localStorage: { ...JSON.parse(JSON.stringify(window.localStorage)) }
                    }
                  }
                });
              }

              break;
            default:
              break;
          }
        };

        break;
      case GameEvents.USER_EVENT:
        doLogic = async () => {
          try {
            const { event_name, payload } = event?.payload;

            // AI analytics on arena side
            switch (event_name) {
              case 'VirtualItemSpend':
                const { id, internalPrice } = payload;

                if (id && internalPrice) {
                  Analytics.trackEvent(Analytics.gems.gemSpendingPowerUp(id, internalPrice), true);

                  if (id === (SS_HURDLE_BOOST_PROPS.HINT_NAME as string)) {
                    window.sessionStorage.setItem(
                      SS_HURDLE_BOOST_PROPS.USED_hurdle_boost_clue,
                      'true'
                    ); // to not send if user already seen / used hint
                  }
                } else {
                  globalLogHandler({
                    msg: `AI ${event_name} not sent - no id or internalPrice in payload`,
                    filename: 'GameUnit.tsx',
                    value: `payload: ${payload}`
                  });
                }

                this.props.dispatch(GemsEffects.UpdateGemsAmount());
                break;

              case GameUserEventsNames.HURDLE_ENTER_TRY as string: // continuing watching this to track successful tries
                const { Reason, greenGuessed } = payload;

                // reset of used hint flag
                if (Reason === (GameUserEventsResults.SUCCESS as string) && greenGuessed === 5) {
                  // new hurdle, reset hint usage option
                  window.sessionStorage.removeItem(SS_HURDLE_BOOST_PROPS.USED_hurdle_boost_clue);
                }

              default:
                break;
            }

            const leanplumPayload = { ...payload };
            // HURDLE - EnterTry / EnterSecondInvalidTry
            const isHurdle = (window as any)?.STORE?.getState()?.gameArena5Slug === 'hurdle';
            // old event still sent by game, but we don't want to send it to leanplum
            const hurdleTrackedNameOld = GameUserEventsNames.HURDLE_ENTER_TRY;

            // new event name 'EnterSecondInvalidTry' - fired after 2nd try and calculated on game side
            if (isHurdle && event_name === hurdleTrackedNameOld) {
              return; // not send old event 'hurdle_boost_clue' still provided by game
            }

            // main LP event
            LeanplumAnalytics.trackEvent(
              event_name,
              { ...leanplumPayload, game: this?.props?.game?.slug },
              true
            );
          } catch (err) {
            globalErrorHandler({
              error: err,
              filename: 'GameUnit.tsx',
              info: `Game events error (could not send ${GameEvents.USER_EVENT}) to leanplum`
            });
          }
        };

        break;
      case GameEvents.ANALYTICS_EVENT:
        doLogic = async () => {
          const { event_name, payload } = event?.payload || {};

          trackGameEvent(event_name, payload, this.props.game);

          if (event_name?.toLowerCase?.() === 'Boost'.toLowerCase()) {
            dispatch(GemsEffects.UpdateGemsAmount());
          }
        };

        break;
      default:
        break;
    }

    doLogic = doLogic ? doLogic.bind(this) : doLogic;
    doLogic && setTimeout(await doLogic, 5);
  };

  componentDidUpdate(prevProps: GameUnitProps) {
    // for sending AUTH_API_EVENT callback action when outer logic done
    const { user, isIframeGame, gameState } = this.props;

    // When iframe game requested to login and user logged in
    if (prevProps?.user?.uid !== user?.uid) {
      // fixes #144571, #146488
      // will work when games/eagle-user-client update lets handle this
      this.props.postMessageToIframe({
        type: IframeMessageTypes.AUTH_API_EVENT,
        payload: {
          auth_method: IframeAuthApiCallbackActions.CHECK_AUTH
        }
      });
    }

    !isIframeGame && gameState === GameState.GAME && checkFPS();
  }

  handlePurchaseRequestEvent = (event: PurchaseRequestEvent) => {
    const { dispatch } = this.props;
    const purchaseRequestId = event.payload.payload;

    this.state.requestEventsQueue.push(purchaseRequestId);

    lsSetGamePurchaseRequest('pending');
    LocalStorageService.addStorageListening({
      [LocalStorageListenedProps.GAME_PURCHASE_REQUEST]: this.storageListener
    });
    LocalStorageService.setItem('inGamePurchaseRequest', LocalStorageListenedProps.GAME_PURCHASE_REQUEST);
    batch(() => {
      dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SHOP_TAB));
      dispatch(setGemsShopLocation(GemsAnalyticsShopLocations.GAME)); // this is not doing much, because shop opens in a new tab
    });
  };

  sendResponseEvent = (isSuccess: boolean) => {
    const { requestEventsQueue } = this.state;

    requestEventsQueue.forEach((id, index) => {
      const result = index === requestEventsQueue.length - 1 && isSuccess;
      const responseEvent: PurchaseResponseEvent = {
        type: GameEvents.PURCHASE_RESPONSE,
        payload: {
          payload: id,
          result
        }
      };

      this.gameSubject.dispatch(responseEvent, this.props.postMessageToIframe.bind(this));
    });

    this.setState({ requestEventsQueue: [] });
  };

  storageListener = (event: StorageEvent) => {
    const { newValue } = event;
    const { dispatch } = this.props;

    dispatch(setSideMenuOpened(false));

    if (newValue === 'success') {
      this.sendResponseEvent(true);
    } else if (newValue === 'fail') {
      this.sendResponseEvent(false);
    }

    LocalStorageService.removeStorageListening(LocalStorageListenedProps.GAME_PURCHASE_REQUEST);
  };

  onResize = () => {
    const { gameState, adFree } = this.props;
    const isDesktopWidthDevice = window.matchMedia(`(min-width: ${ArkCssBreakpoints.ARK_SMALL_DESKTOP}px)`).matches;
    const unitContainer = this.unitContainerRef.current;
    const canvasContainer = this.canvasContainerRef.current;

    if (!unitContainer || !canvasContainer) {
      return;
    }

    // fix for mobile adfree
    if (adFree && !isDesktopWidthDevice && gameState === GameState.GAME) {
      const componentHeight = window.innerHeight;

      canvasContainer.style.height = `${componentHeight}px`;
    }
  };

  componentDidMount() {
    this.onResize();

    const { isIframeGame } = this.props;

    window.addEventListener('message', this.onGameEvent);

    if (!isIframeGame) {
      this.loadGameSdk();
      this.gameSubject.xSubscribe(this.onGameEvent);
    }

    window.addEventListener('resize', this.onResize);
    window.addEventListener('message', this.onMessage);
    this.marketingActionHandlerFunc = handleLeanplumMarketingActions.bind(this)(undefined, true);
  }

  componentWillUnmount() {
    const { isIframeGame, dispatch } = this.props;

    if (isIframeGame) {
      window.removeEventListener('message', this.onGameEvent);
    } else {
      this.gameSubject.xUnsubscribe();
      dispatch(setGameObservable(null));
    }

    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('message', this.onMessage);
    // All game pages use real <a> links to navigate for reloading to clear game data => no removing listener for LP is needed... But to be sure
    handleLeanplumMarketingActions.bind(this)(undefined, this.marketingActionHandlerFunc);
    this.marketingActionHandlerFunc = null;
  }

  getIncognitoSdkOptions() {
    const aiUserId = ((CookieService.getArkCookie('ai_user') || '|') as string).split('|')[0];
    const userEagleId = UserService.getUserFromStore()?.uid;
    const fingerprintGlobalCDs = FingerprintService.detected;

    devConsole('ai.user.id passed to game: ', aiUserId);
    devConsole('userEagleId passed to game: ', userEagleId);
    return {
      ...(aiUserId ? { aiUserId } : {}),
      ...fingerprintGlobalCDs,
      ...(userEagleId ? { userEagleId } : {})
    };
  }

  getEnviromentObjectForGame = () => {
    const authApi = UserService.authApiService;
    const paymentApi = PaymentService.paymentApiService;
    const virtualItemsApi = GemsService.gemsApiService;
    const userGameDataApi = authApi.getUserGameDataApi({ apiRoot: environment.EAGLE_USER_GAME_DATA_API });

    return {
      '@arkadium/eagle-user-client': authApi,
      '@arkadium/eagle-virtual-items-api-client': virtualItemsApi,
      '@arkadium/eagle-payments-api-client': paymentApi,
      userGameDataApi,
      nestEnvironment: environment.NEST_ENVIRONMENT,
      arenaEnvironment: MiscUtils.adaptEnvNameToMatchArenaEnvs(environment.Name)
    };
  };

  prepareOptionsForGame = () => {
    const { gameAssetOriginUrl, externalConfigUrl, sdkName } = this.props.game;
    const { currentLang } = this.props;
    const options: any = {
      assetOriginUrl: gameAssetOriginUrl,
      configUrl: externalConfigUrl ?? '',
      locale: currentLang,
      rewardVideoAvailable: true,
      interstitialVideoAvailable: true,
      eventList: [],
      arenaName: environment.ARENA_DOMAIN,
      sdkName: sdkName,
      ...this.getIncognitoSdkOptions()
    };

    if (this.props.isIframeGame) {
      options.abVariations = AbTestService.getVariationFromStore();
    }

    if (!this.props.isIframeGame) {
      options.enviroment = this.getEnviromentObjectForGame();
    }

    return options;
  };

  onIframeLoad = () => {
    const { postMessageToIframe } = this.props;

    this.setState({ isIframeLoaded: true });

    const options = this.prepareOptionsForGame();
    const storageData = {
      type: IframeMessageTypes.UPDATE_LOCAL_STORAGE,
      payload: { ...JSON.parse(JSON.stringify(window.localStorage)), passedCookiesData: `${document.cookie}` }
    };

    if (UserService.isUserSubscriber() && options?.enviroment?.['@arkadium/eagle-user-client']) {
      delete options.enviroment['@arkadium/eagle-user-client']; // not needed prop and fixes #148937 for subscribers when page not reloaded
    }

    const gameData = {
      type: IframeMessageTypes.PASS_GAME_DATA,
      // passedCookiesData is needed because undafe cookies are not taken by game in iframe
      payload: {
        localstorage: {
          ...JSON.parse(JSON.stringify(window.localStorage)),
          passedCookiesData: `${document.cookie}`
        },
        options,
        nestEnvironment: environment.NEST_ENVIRONMENT,
        arenaEnvironment: MiscUtils.adaptEnvNameToMatchArenaEnvs(environment.Name)
      }
    };

    postMessageToIframe(storageData);
    postMessageToIframe(gameData);
  };

  loadGameSdk() {
    const {
      dispatch,
      game: { gameAssetOriginUrl, sdkName }
    } = this.props;

    HttpUtils.loadScript(`${gameAssetOriginUrl}main.min.js`).then(() => {
      try {
        const sdk = (window as any).__arenax_v1__[`${sdkName}Game`].game;
        const options = this.prepareOptionsForGame();

        sdk.register(this.gameSubject, options);
        dispatch(setGameObservable(this.gameSubject));
        sdk.render(0, 0, 'game-canvas');
      } catch (e) {
        globalErrorHandler({ error: e, filename: 'GameUnit.tsx', info: 'HttpUtils.loadScript()' });
      }
    });
  }

  render() {
    const { gameState, keepAliveStatus, isIframeGame, iframeRef, iframeSourceCode } = this.props;

    return (
      <UnitContainer
        data-element-description="game"
        ref={this.unitContainerRef}
        hidden={gameState !== GameState.GAME || !keepAliveStatus}
      >
        <CanvasContainer ref={this.canvasContainerRef}>
          <GameCanvas
            renderId="game-canvas"
            isIframeGame={isIframeGame}
            iframeSourceCode={iframeSourceCode}
            onIframeLoad={this.onIframeLoad.bind(this)}
            iframeRef={iframeRef}
          />
        </CanvasContainer>

        {this.state.isGameLoading && <GameLoader />}
      </UnitContainer>
    );
  }
}

const UnitContainer = React.forwardRef<any, any>(({ hidden, ...props }: any, ref) => (
  <div
    ref={ref}
    {...props}
    className={classNames(styles.unitContainer, {
      [styles.hidden]: hidden
    })}
  />
));
const CanvasContainer = React.forwardRef<any, any>((props: any, ref) => (
  <div className={styles.canvasContainer} {...props} ref={ref} />
));

export const GameUnit = connect((state) => ({
  shopLocation: state.gemsShopLocation
}))(GameUnitBase);
