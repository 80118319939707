import React from 'react';

import { MobileQueryOrientation, Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';
import styles from './GamePageAd.css';
import { GamePageDisplayAdProps } from './GamePageDisplayAdProps';

export const GameAreaTopAdMobilePortrait = React.memo((props: GamePageDisplayAdProps) => {
    return (
        <Responsive
            orientation={MobileQueryOrientation.PORTRAIT}
            maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}
            minWidth={320}
        >
            <div className={props.className}>
                <Responsive minWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}>
                    <Ad
                        className={styles.mobileTopAd}
                        adType={[AdTypes.AD_728x90]}
                        backgroundTone={props.adBackgroundTone}
                        keepSize
                        captionPosition={CaptionPositionTypes.CENTER}
                        id="ark_display_m1"
                        useDefaultBidTimeout
                        hideCaption
                    />
                </Responsive>
                <Responsive maxWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE_BELOW}>
                    <Ad
                        className={styles.mobileTopAd}
                        adType={[AdTypes.AD_320x100, AdTypes.AD_320x50]}
                        backgroundTone={props.adBackgroundTone}
                        keepSize
                        captionPosition={CaptionPositionTypes.CENTER}
                        id="ark_display_m1"
                        useDefaultBidTimeout
                        hideCaption
                    />
                </Responsive>
            </div>
        </Responsive>
    );
});
