const loadScript = (url) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;

        if (script.readyState) {
            // IE
            script.onreadystatechange = () => {
                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            // Others
            script.onload = resolve;
            script.onerror = reject;
        }

        script.src = url;
        document.head.appendChild(script);
    });
};

export const checkFPS = () => {
    //check QP
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('perf_meter') !== null) {
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/stats.js/7/Stats.min.js').then(() => {
            const targetContainer = document.querySelector('[class*="Game-canvasBox"]');
            if (!targetContainer) {
                return;
            }
            const stats = new Stats();
            const wrapper = document.createElement('div');
            const title = document.createElement('h5');
            const isMobile = window.innerWidth < 640;
            title.style.margin = '0 8px';
            title.innerHTML = 'Game FPS';
            wrapper.style.backgroundColor = '#fff';
            // stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
            wrapper.style.position = 'absolute';
            wrapper.style.top = '0';
            wrapper.style.right = '0';
            stats.domElement.style.zoom = isMobile ? '1' : '2';
            wrapper.appendChild(title);
            wrapper.appendChild(stats.domElement);
            targetContainer.appendChild(wrapper);
            requestAnimationFrame(function loop() {
                stats.update();
                requestAnimationFrame(loop);
            });
        });
    }
};
