import React, { FC, memo } from "react";

import Sticky from 'react-stickynode';

import styles from './GamePageAd.css';
import { GamePageDisplayAdProps } from './GamePageDisplayAdProps';
import { DesktopQuery, Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';

type GameAreaBottomAdProps = {
    bgColor?: string;
    abVariant?: string;
} & GamePageDisplayAdProps;

const GameAreaBottomAd: FC<GameAreaBottomAdProps> = memo((props: GameAreaBottomAdProps) => {
    const { bgColor, abVariant, adBackgroundTone, className } = props;

    return (
        <DesktopQuery>
            <div className={styles.gameAreaBottomContainer}>
                <Sticky className={styles.stickyContainer}>
                    <div className={styles.bottomAdsWrapper} style={{ backgroundColor: bgColor }}>
                        <div className={className}>
                            {abVariant === 'experiment' ? (
                                <>
                                    <Responsive
                                        minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}
                                        maxWidth={ArkCssBreakpoints.ARK_MEDIUM_LG_DESKTOP_BELOW}
                                    >
                                        <Ad
                                            adType={[AdTypes.AD_320x100, AdTypes.AD_320x50]}
                                            backgroundTone={adBackgroundTone}
                                            keepSize
                                            captionPosition={CaptionPositionTypes.CENTER}
                                            className={styles.bottomAd}
                                            id="ark_display_t2"
                                            useDefaultBidTimeout
                                        />
                                    </Responsive>
                                    <Responsive
                                        minWidth={ArkCssBreakpoints.ARK_MEDIUM_LG_DESKTOP}
                                        maxWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP}
                                    >
                                        <Ad
                                            adType={[AdTypes.AD_728x90]}
                                            backgroundTone={adBackgroundTone}
                                            keepSize
                                            captionPosition={CaptionPositionTypes.CENTER}
                                            className={styles.bottomAd}
                                            id="ark_display_t2"
                                            useDefaultBidTimeout
                                        />
                                    </Responsive>
                                </>
                            ) : (
                                <Responsive maxWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP}>
                                    <Ad
                                        adType={[AdTypes.AD_728x90]}
                                        backgroundTone={adBackgroundTone}
                                        keepSize
                                        captionPosition={CaptionPositionTypes.CENTER}
                                        className={styles.bottomAd}
                                        id="ark_display_t2"
                                        useDefaultBidTimeout
                                    />
                                </Responsive>
                            )}

                            <Responsive minWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP_ABOVE}>
                                <Ad
                                    adType={[AdTypes.AD_970x90, AdTypes.AD_728x90]}
                                    backgroundTone={adBackgroundTone}
                                    keepSize
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    className={styles.bottomAd}
                                    id="ark_display_t2"
                                    useDefaultBidTimeout
                                />
                            </Responsive>
                        </div>
                    </div>
                </Sticky>
            </div>
        </DesktopQuery>
    );
});

export default GameAreaBottomAd;
