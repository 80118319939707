import React, { useEffect } from 'react';

import { OpenWebProvider } from '@open-web/react-sdk';
import { useDispatch, useSelector } from 'react-redux';

import { environment } from '../../config/environment';
import { IGame } from '../../models/Game/Game';
import { Analytics } from '../../services/Analytics/Analytics';
import { OpenWebService } from '../../services/OpenWebService';
import UserService from '../../services/UserService';
import { setOpensModalElementRef } from '../../store/ducks/layout';

type OpenWebProviderComponentProps = {
    topicId?: string;
    game: IGame;
};

const spotId = environment.OPEN_WEB_KEY;

export const OpenWebProviderComponent = ({ topicId, game }: OpenWebProviderComponentProps) => {
    const user = useSelector((store) => store.user);
    const dispatch = useDispatch();

    useEffect(() => {
        const loginOpenWeb = async () => {
            const token = await UserService.getToken();

            OpenWebService(user.uid, token);
        };

        user && loginOpenWeb();
    }, [user]);

    return (
        <OpenWebProvider
            spotId={spotId}
            tracking={{
                // if user is not logged in arkcom, logout openweb
                ['spot-im-api-ready']: () => {
                    if (!user) {
                        (window as any)?.SPOTIM?.logout();
                    }
                },
                ['spot-im-login-start']: () => {
                    /**
                     * This wild query is used because we have a modal opening element
                     * inside Shadow DOM (Log In button). And we don't have access to
                     * code inside this Shadow DOM tree. So this is the only way ho we
                     * could get access to this element.
                     */
                    const logInBtn = document
                        .querySelector('[data-spotim-module="conversation"] div:first-child')
                        ?.shadowRoot?.querySelectorAll(
                            '[data-spot-im-class="registration-buttons"] button'
                        )[1] as HTMLElement;
                    const tabPanel = document.querySelector('#comments-tabpanel') as HTMLElement;

                    dispatch(setOpensModalElementRef(logInBtn || tabPanel));
                    UserService.openSignInPanel();

                    if (game) {
                        Analytics.trackEvent(Analytics.games.commentSignInUp(game));
                    } else if (topicId) {
                        // Analytics.trackEvent(Analytics.games.commentSignInUp(topicId));
                        /// CRV ToDo: add another analytics if needed (e.g. for blogposts)
                    }
                },
                ['spot-im-clicked-cog-menu']: () => {
                    //there is no event handler for dropdown menu showing up so we have to do some digging
                    const observer = new MutationObserver((mutations) => {
                        observer.disconnect();
                        mutations.forEach((mutation) => {
                            const cogWheel = mutation.target as HTMLElement;

                            if (!cogWheel.className.includes('Menu__isOpen')) {
                                return;
                            }

                            const { shadowRoot } = document.querySelector('div[open-web-ui-components-menus]');
                            const logOutBtn = shadowRoot.querySelector(
                                'button[data-spot-im-class="logout-item"]'
                            ) as HTMLButtonElement;

                            logOutBtn.addEventListener(
                                'click',
                                () => {
                                    UserService.userLogout();
                                    game && window.location.reload();
                                },
                                { once: true }
                            );
                        });
                    });
                    const toObserve = document
                        .querySelector('[data-spotim-module="conversation"] :first-child')
                        .shadowRoot.querySelector(
                            "div[data-spot-im-class='registration-buttons'] > span[class^='Menu__menuButtonContainer']"
                        );

                    observer.observe(toObserve, { attributeFilter: ['class'] });
                },
                ['spot-im-current-user-sent-message-clicked']: (event) => {
                    event.preventDefault();

                    if (game) {
                        Analytics.trackEvent(Analytics.games.commentSubmit(game));
                    } else if (topicId) {
                        // Analytics.trackEvent(Analytics.games.commentSubmit(topicId));
                        /// CRV ToDo: add another analytics if needed (e.g. for blogposts)
                    }
                },
            }}
        />
    );
};
